.Site
  width: 100%
  font-size: 16px
  font-weight: 400
  line-height: 26px
  max-width: 1440px
  margin-left: 50%
  transform: translateX(-50%)

.Site-DefaultText, .Site-DescriptionText
  font-size: 16px
  line-height: 26px
.Site-LinkText
  font-size: 16px
  line-height: 26px
  font-weight: 600
  color: var(--blackColor)

.Site-DescriptionText
  color: var(--descriptionDark)
  font-weight: 400
.Site-TitleText
  font-size: 22px
  font-weight: 700
  line-height: 32px
  color: var(--blackColor)
.Site-SectionTitle
  font-size: 38px
  line-height: 48px
  font-weight: 600
  text-transform: uppercase
  position: relative
  z-index: 2
  display: flex
  justify-content: center
.Site-SectionSubTitle
  font-size: 16px
  line-height: 26px
  color: var(--descriptionDark)
  text-align: center
.Site-SubsectionTitle
  font-size: 28px
  font-weight: 600
  line-height: 38px

.Site-PhoneNumber
  color: var(--accentColor)

.Site-Fields
  .Field-Textarea
    height: 110px !important
    textarea
      height: 100%
      padding-top: 16px
  .Field
    border: 2px var(--descriptionLight) solid
    border-radius: 16px
    height: 52px
    display: flex
    padding: 0px 24px
    margin-top: 42px
    background-color: #fff
    &:focus-within
      border: 2px var(--accentColor) solid
      box-shadow: var(--whiteBoxShadow)
    input
      padding: 0
      background: none
      order: 1
      margin-left: 12px
    .Field-Icon
      margin-right: 0
      width: 16px
      height: 16px
      font-size: 16px
      display: flex
      justify-content: center
      align-items: center
      order: 0
      color: var(--descriptionDark)
    .Field-SelectAngle-Icon
      order: 2
    label
      top: -42px
      left: 0
      font-size: 16px
      font-weight: 700
      height: 26px
      background: none
      text-transform: none !important
      margin-bottom: 0
  .Field_status_invalid
    border: 2px var(--notyColor) solid !important

  .Field-Bar
    display: none

  .Phone
    grid-template-columns: 110px minmax(0, 1fr)
    grid-gap: 24px
    .CountryFlag
      margin-top: 0
      margin-right: 8px
    .DropdownBox-Container
      left: 0
      min-width: 240px !important
      .Field
        height: 32px
        padding: 0px 16px
        border-radius: 8px
      .DropdownBox-Triangle
        display: none
  .Field-Error
    display: none

  .Checkbox
    align-items: flex-end
  .Field-Checkbox
    height: 52px
    margin-bottom: 0
    input
      width: 24px
      height: 24px
      top: 3px
      left: 2px
    span
      padding-left: 36px
      font-size: 16px
      &::before
        position: absolute
        top: 0
        left: 0
        box-sizing: content-box
        margin: 1px 12px 0px 0px
        border: solid 2px var(--descriptionDark)
        border-radius: 8px
        width: 24px
        height: 24px
      &::after
        content: ""
        display: block
        position: absolute
        top: 6px
        left: 4px
        width: 14px
        height: 6px
.Site-Backdrop
  width: 100%
  height: 100%
  .ConfirmationForm
    margin-left: 50%
    transform: translateX(-50%)
    position: absolute
    top: 80px

.Site-StatusMessage
  width: 100%
  border-left: 0
  padding: 8px 16px
  height: auto

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

@media screen and ( max-width: 1024px )
  .Site-TitleText
    font-size: 18px
    line-height: 28px
  .Site-SectionTitle
    font-size: 32px
    line-height: 42px
