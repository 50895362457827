.Field-Select
  svg
    transition: all 0.2s ease
  .SelectOption-Element
    display: flex
    justify-content: space-between
    .Icon
      margin-top: 4px
      margin-left: 12px
  .SelectOption-Element_theme_active
    background: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    color: #fff

.Select
  width: 100%
  height: 42px
.SelectTags-Container
  display: flex
  padding-left: 8px
  padding-top: 8px

.SelectOptionTag-Container
  display: flex
  align-items: center
.SelectOption-Tag
  padding: 2px 4px
  color: var(--defaultText)
  background-color: var(--borderColor)
  font-size: 12px
  border-radius: 4px
  &:not(:last-child)
    margin-right: 8px
.SelectOption-CircleTag
  border-radius: 100%
  flex-shrink: 0
  width: 20px
  height: 20px
  display: flex
  align-items: center
  font-size: 11px

.Field-Select_Focused
  .Field-Icon
    svg
      transform: rotate(180deg)
.SelectField-EditIcon
  svg
    transform: rotate(0deg) !important
