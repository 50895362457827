.CallBar
  height: 64px
  background-color: #39393D
  border-top-left-radius: 16px
  border-top-right-radius: 16px
  padding: 0px 16px
  display: flex
  align-items: center
  font-weight: 600
  justify-content: space-between


.CallBar-InfoBlocks
  display: flex
  align-items: center

.CallBar-Tools
  display: flex
  align-items: center

  .CallTracker
    margin-right: 24px
  .DialerButton_theme_number_pad
    margin-left: 12px
