.Funnel-ProgressBar
  width: 100%

.ProgressBar-Container
  margin-top: 8px
  grid-gap: 4px
  display: grid
  .FunnelStep
    width: 100%
    height: 4px
    border-radius: 4px
    background-color: var(--descriptionLight)
    display: block
  .FunnelStep_theme_active
    background-color: var(--accentColor)
  .FunnelStep_finish_success, .FunnelStep_finish_fail
    grid-column: 1/-1
  .FunnelStep_finish_success
    background-color: var(--accentColor)
  .FunnelStep_finish_fail
    background-color: var(--notyColor)
