.Filters-NavBlock
  width: 250px
  padding-right: 16px
  border-right: 1px var(--descriptionLight) solid
  margin-right: 16px

  .Nav-Category
    display: flex
    margin-bottom: 16px
    align-items: center
    justify-content: space-between
    h4
      margin-top: 0
      height: auto
      color: var(--descriptionDark)

  .NavBlock-Items
    display: grid
    grid-template-columns: 1fr

    .NavBlock-Item
      position: relative
      width: 100%
      font-size: 14px
      display: flex
      justify-content: space-between
      align-items: center
      padding: 6px 8px
      cursor: pointer
      &:not(:last-child)
        border-bottom: 1px var(--inputBorder) solid
      .Button
        width: auto
        height: auto
        background: none
        box-shadow: none
      .DropdownBox-Container
        right: 0
        left: auto
      &:hover
        color: var(--accentColor)
        .Button
          color: var(--accentColor)
    .NavBlock-Item_theme_active
      color: var(--accentColor)

  .Buttons-Container
    margin-top: 16px
    display: flex
    justify-content: center
