.LooseBlock
  height: 32px
  width: 100%
  border: 1px var(--descriptionLight) dashed
  color: var(--descriptionDark)
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: center
  span
    &:first-child
      margin-right: 4px
.LooseBlock_theme_result
  background: var(--notyColor)
  color: #fff
  border: 1px var(--notyColor) solid
