.BarInfoBlock
  font-size: 11px
  padding: 12px 24px
  cursor: pointer
  height: 64px
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  &:before, &:after
    content: ''
    width: 1px
    height: 40px
    display: block
    position: absolute
    background-color: #4E4F53
    top: 12px
    left: 0
  &:after
    right: 0
    left: auto
  &:hover
    background: #4E4F53
  &:not(:first-child)
    margin-left: -1px

.BarInfoBlock_theme_active
  background: #4E4F53
.BarInfoBlock-ActiveCounter
  color: #fff
