.Market
  padding-top: 16px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.Market-Title
  font-size: 28px
  font-weight: 700
  line-height: 38px
.Market-Description
  font-size: 14px
  color: var(--descriptionDark)
.Market-Apps
  display: grid
  grid-gap: 16px
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))
