.UserProfile
    display: flex
    align-items: center
    position: relative
    height: 64px

.UserProfile-ProfileLabel
    font-size: 11px
    font-weight: 600
    color: #BFC0CC
    margin-right: 16px

.ProfileLabel-UserName
    color: #000