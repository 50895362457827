.ExtensionsCategories
  width: 100%
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(max-content, 0))
  justify-content: center
  grid-gap: 16px
  .Category
    padding: 4px 8px
    border-radius: 12px
    border: 1px var(--descriptionLight) solid
    color: var(--descriptionDark)
    transition: all 0.3s ease
    &:hover
      border: 1px var(--accentColor) solid
      color: var(--blackColor)
      padding-top: 3px
      padding-bottom: 5px
  .Category_theme_active
    background-color: var(--accentColor)
    color: #fff
    border: 1px var(--accentColor) solid
    &:hover
      color: #fff
  .CategorySkeleton
    border-radius: 12px
