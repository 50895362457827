.Site-Pricing
  padding: 80px 0px
  background-color: #fff
.Site-Pricing-Container
  display: grid
  grid-template-columns: 1fr 380px
  grid-gap: 80px
  margin-top: 64px

.Site-Pricing-InfoBlock
  width: 100%
  background-color: #fff
  padding: 24px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
  border: 2px var(--descriptionLight) solid
  border-radius: 16px
  .Pricing-Note
    padding-top: 24px
    border-top: 2px var(--descriptionLight) solid
  .Pricing-TarifDescription-Container
    display: grid
    grid-gap: 24px
    grid-template-columns: 1fr
  .MarkedText
    width: 100%
    display: grid
    grid-template-columns: 1fr 18px
    grid-gap: 24px
    .MarkedText-Icon
      width: 18px
      height: 18px
      display: flex
      justify-content: center
      align-items: center
      background-color: var(--accentColor)
      color: #fff
      font-size: 11px
      border-radius: 18px
.Site-Pricing-TarifTiles
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
  .TarifTiles-Title
    text-align: center
  .Pricing-SubmitButton
    display: flex
    justify-content: center
.Site-TarifTile
  padding: 16px 24px
  background-color: #fff
  border: 2px var(--descriptionLight) solid
  display: grid
  grid-template-columns: 24px minmax(min-content, 1fr) minmax(min-content, max-content)
  grid-gap: 16px
  border-radius: 16px
  cursor: pointer
  align-items: center
  .TarifTile-CheckIcon
    width: 24px
    height: 24px
    background-color: #fff
    border-radius: 24px
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-size: 14px
    border: 2px var(--descriptionLight) solid
  .TarifTile-Info
  .TarifTile-Info-Title, .TarifTile-Price
    font-size: 22px
    line-height: 32px
    font-weight: 700
  .TarifTile-Info-Discount
    font-weight: 600
    color: var(--accentColor)
  .TarifTile-Price
    display: flex
    flex-direction: column
    align-items: flex-end
    .Price-OldPrice
      font-size: 15px
      color: var(--descriptionDark)
      text-decoration: line-through
      .Price-Currency
        font-size: 12px
        color: var(--descriptionDark)
    .Price-Currency
      font-size: 16px
      font-weight: 400
      color: var(--blackColor)
.Site-TarifTile_active
  background-color: var(--accentColor)
  border: 2px var(--accentColor) solid
  .TarifTile-CheckIcon
    border: 2px #fff solid
    color: var(--accentColor)
  .TarifTile-Info-Title, .TarifTile-Price
    color: #fff
  .TarifTile-Price
    .Price-Currency, .Price-OldPrice
      color: #fff
    .Price-OldPrice
      .Price-Currency
        color: #fff
  .TarifTile-Info-Discount
    color: var(--acidColor)

@media screen and ( max-width: 1024px)
  .Site-Pricing-Container
    grid-template-columns: 1fr
