.Task-Attachments
  padding-left: 24px
  .Attachment
    width: 100%
    display: flex
    margin-bottom: 16px

  .Attachment-Prepare
    width: 100%
    height: 80px
    border-radius: 4px
    background-color: #F2F4F6
    display: flex
    justify-content: center
    align-items: center

    .Prepare-Container
      display: flex
      align-items: center
      width: 100%
      justify-content: center

      .Loading-Text
        margin-left: 4px

  .Attachment-FileBlock
    width: 124px
    height: 80px
    border-radius: 4px
    background-color: #F2F4F6
    display: flex
    align-items: center
    justify-content: center
    font-size: 18px
    font-weight: 600
    color: var(--sideLinks)
    margin-right: 16px
    flex-shrink: 0
    text-transform: uppercase
    text-decoration: none !important

  .Attachment-Details
    width: 100%

    .Details-Name
      font-size: 14px
      font-weight: 700

    .Details-Date
      color: var(--sideLinks)

    .Details-Actions
      font-size: 12px
      margin-top: 4px
      button
        color: var(--sideLinks)
        background: none
        text-decoration: underline
        cursor: pointer
        margin: 0
        padding: 0
        outline: none
        appearance: none
        border: 0

.Task-Attachments-Field-Hidden
  cursor: pointer !important
  display: block
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  opacity: 0
