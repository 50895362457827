.Task-Term
  padding: 4px 8px
  background-color: var(--borderColor)
  border-radius: 4px
  color: var(--descriptionDark)
  cursor: pointer
  span
    font-size: 12px
    margin-left: 8px
    font-weight: 300

.Task-Term_theme_done
  background-color: #BEF0C7
  color: #029E1E
  margin-left: 8px
