.AuthForm
  margin-top: 64px
  width: 450px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 32px
  margin-left: calc((50% - 450px) / 2)
  .AuthForm-MobileSignStep
    display: none

.AuthForm-Header
  display: grid
  grid-gap: 12px
  grid-template-columns: 1fr

  .AuthForm-Title
    font-size: 38px
    font-weight: 600

  .AuthForm-Description
    color: var(--descriptionDark)

.AuthForm-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px

.AuthForm-Button
  height: 52px
  font-size: 16px
  font-weight: 600
  border-radius: 16px
  .Icon
    font-size: 16px
    color: var(--accentColor)

.AuthForm-SignIn-PasswordField
  position: relative
  .AuthForm-ForgotPassword
    color: var(--accentColor)
    position: absolute
    right: 0
    &:hover
      text-decoration: underline !important
