.ManageInfoBlock-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
.ManageInfoBlockPopUp-DescriptionLine
  width: 100%
  display: inline-grid
  grid-template-columns: 1fr 1fr 12px
  grid-gap: 16px
  align-items: center
  .Field
    margin-bottom: 0
.ManageInfoBlockPopUp-DescriptionLine-FunctionButton
  width: 12px
  height: 12px
  font-size: 8px
.ManageInfoBlockPopUp-DescriptionLinesContainer
  display: grid
  grid-column: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  grid-gap: 16px
