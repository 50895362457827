.ConfirmationForm
  padding: 48px 64px
  background-color: #fff
  border-radius: 16px
  width: 100%
  max-width: 580px
  display: grid
  grid-template-columns: 1fr
  justify-items: center
  grid-gap: 24px
  .AuthForm-Header
    text-align: center
  .AuthForm-Title
    line-height: 48px
  .AuthForm-Description
    span
      color: var(--accentColor)
      cursor: pointer
      &:hover
        text-decoration: underline

.ConfirmationForm-Container
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
  justify-items: center
  .Site-Fields
    width: 100%

.ConfirmationForm-Icon
  width: 64px
  height: 64px

.ConfirmationForm-Buttons
  width: 100%
  display: flex
  justify-content: center
  .Button
    min-width: 200px

.ConfirmationForm-CodeInput
  justify-self: center
  width: auto !important
  margin: 24px 0px
  input
    font-family: var(--defaultFont) !important
    color: var(--blackColor) !important
    border-radius: 16px !important
    border: 2px var(--descriptionLight) solid !important
    &:not(:last-child)
      margin-right: 24px
    &:focus
      caret-color: var(--accentColor) !important
      border: 2px var(--accentColor) solid !important

.ConfirmationForm-CodeInput_status_fail
  input
    border: 2px var(--notyColor) solid !important
    color: var(--notyColor) !important
    &:focus
      caret-color: var(--notyColor) !important
      border: 2px var(--notyColor) solid !important

.ConfirmationForm-LinkedText
  text-align: center
  color: var(--descriptionDark)
  span
    color: var(--accentColor)
    cursor: pointer
    &:hover
      text-decoration: underline

  .NotActiveLink
    color: var(--descriptionDark) !important
    text-decoration: none !important
    cursor: text

.ConfirmationForm-BackButton
  font-size: 18px
  color: var(--accentColor) !important
  &:hover
    text-decoration: underline
  .Icon
    font-size: 18px
