.Site-ContactUs
  background-color: #fff
  padding: 80px 0px
.Site-ContactUs-Container
  margin-top: 64px
  display: grid
  grid-template-columns: max-content 1fr
  grid-gap: 80px
  .ContactUs-Form
    border-radius: 16px
    width: 550px
    padding: 48px
    background-color: var(--backgroundColor)
    display: grid
    grid-template-columns: 1fr
    grid-gap: 24px
  .ContactUs-Contacts
    display: grid
    grid-template-columns: 1fr
    grid-auto-rows: max-content
    grid-gap: 32px
  .ContactUs-SubmitButton
    display: flex
    justify-content: center
    .Button
      min-width: 220px
@media screen and ( max-width: 1024px )
  .Site-ContactUs-Container
    grid-template-columns: 1fr
    .ContactUs-Form
      justify-self: center
      order: 1
    .ContactUs-Contacts
      grid-template-columns: repeat(3, minmax(0, 1fr))
      order: 0
      .Site-Footer-Block
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .Footer-Block-Links
          text-align: center
@media screen and ( max-width: 768px )
  .Site-ContactUs-Container
    .ContactUs-Form
      width: 100%

@media screen and ( max-width: 576px )
  .Site-ContactUs-Container
    .ContactUs-Contacts
      grid-template-columns: 1fr
    .ContactUs-Form
      padding: 16px
