.Pagination-Container
  display: flex
  padding: 16px 0px
  li
    font-size: 12px
    font-weight: 500
    width: 24px
    height: 24px
    margin-right: 4px
    cursor: pointer
    a
      outline: none
      display: flex
      justify-content: center
      align-items: center
      color: var(--descriptionDark)
      height: 100%
      &:hover
        color: var(--accentColor)
    &:las-child
      margin-right: 0

  .disabled *
    cursor: default
    color: var(--descriptionDark) !important

  .active
    background-color: #fff
    border-radius: 100%
    box-shadow: var(--whiteBoxShadow)
    a
      color: var(--accentColor)
      text-decoration: none

  .previous, .next
    svg
      color: var(--blackColor)
    &:hover
      svg
        color: var(--accentColor)
