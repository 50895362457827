.Site-SectionTitle-Container
  display: flex
  align-items: center
  flex-direction: column
  text-align: center
  .Site-SectionSubTitle
    margin-top: 16px

@media screen and ( max-width: 1024px )
  .Site-SectionTitle-Container
    .Site-SectionTitle
      text-align: center
