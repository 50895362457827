.ErrorsCounter
  width: 100%
  display: inline-grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 16px

.ErrorsCounter-Column
  width: 100%
  .ErrorsCounter-Column-Header
    display: flex
    align-items: center
    justify-content: space-between
  .Column-Title
    font-size: 14px
    line-height: 14px
    font-weight: 700
    color: var(--labelColor)
    margin: 0
    display: block
  .Column-ErrorsCounter
    font-weight: 700
    font-size: 36px
    line-height: 36px
  .ErrorsCounter-Column-Body
    margin-top: 16px
    background-color: var(--pushedButton)
    padding: 8px
    border-radius: 8px
    .Body-Row
      display: flex
      .Instance
      .ErrorsCounter
    .Body-Row-EmptyResult
      text-align: center
