.SceneWithHistory-Container
  display: grid
  grid-template-columns: minmax(450px, 532px) 1fr
  margin-left: -16px
  width: calc(100% + 32px)
  flex: 1
  overflow-y: hidden
  .Container-ContentHolder, .Container-HistoryHolder
    padding: 16px
    overflow-y: scroll
    &::after
      content: ""
      display: block
      height: 1px
      width: 100%
