.StepBlock
  width: 180px
  background-color: #fff
  border-radius: 8px
  box-shadow: var(--whiteBoxShadow)
  padding: 16px
  display: flex
  flex-direction: column
  align-items: center
  height: 160px
.StepBlock-Data
  margin-top: 12px
  display: flex
  align-items: center
.StepBlock-ResultTotal
  font-size: 42px
  font-weight: 700
  color: #fff
.StepBlock-ResultMoney
  font-size: 13px
  font-weight: 700
  color: #fff

.StepBlock-DiffTotalCounter
  text-align: center
  font-size: 24px
  font-weight: 700
.StepBlock-DiffMoneyCounter
  font-size: 13px
  font-weight: 700
  color: var(--descriptionDark)
.StepBlock-DiffMoneyCounter_theme_negative
  color: var(--notyColor)

.StepBlock-Label
  font-size: 14px
  color: var(--descriptionDark)
  font-weight: 700
  line-height: 18px
  height: 36px
  width: 100%
  flex-shrink: 0
.StepBlock-MoneyCounter
  font-size: 13px
  font-weight: 700
  color: var(--blackColor)
  margin-right: 8px
.StepBlock-DataCounter
  font-size: 13px
  font-weight: 700
  color: var(--descriptionDark)
.StepBlock-MoneyCounter, .StepBlock-DataCounter, .StepBlock-DiffMoneyCounter, .StepBlock-ResultMoney
  span
    font-size: 11px

.StepBlock_theme_result
  background: var(--accentColor)
  justify-content: space-between
  .StepBlock-MoneyCounter, .StepBlock-DataCounter, .StepBlock-Label
    color: #fff
