.Dashboard
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-gap: 32px
  padding-bottom: 16px
.Dashboard-Module
  margin-top: 64px !important

.Dashboard-Nav
  margin-top: 16px
  display: flex
  justify-content: center
  .UsersBar
    margin-left: 24px

.Dashboard-Widgets
  display: grid
  grid-template-columns: 488px 1fr
  grid-gap: 16px
.Widgets-LeftContainer, .Widgets-RightContainer
  display: grid
  grid-gap: 16px
  grid-template-columns: 1fr

.Widgets-4x4
  width: 100%
  display: inline-grid
  grid-template-columns: repeat(2, minmax(0, 1fr))
  grid-gap: 16px
.Widgets-3x1
  width: 100%
  display: inline-grid
  grid-template-columns: repeat(3, minmax(0, 1fr))
  grid-gap: 16px

.Widgets-Merged
  background-color: #fff
  padding: 16px
  border-radius: 8px
  box-shadow: var(--whiteBoxShadow)
  .WidgetTile
    background: none
    box-shadow: none
    padding: 0
    border-radius: 0

.Widgets-auto-180
  width: 100%
  display: grid
  grid-template-columns: 2fr 1fr
  grid-gap: 16px
.Widgets-2-inline
  width: 100%
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 16px
.Widgets-2-column
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  grid-gap: 16px
