.Site-UserProfileBar
  display: flex
  align-items: center
  position: relative
  .UserAvatar
    box-shadow: none
    .Icon
      font-size: 16px !important
  .Icon
    color: var(--descriptionDark)
    font-size: 16px
    transition: all 0.3s ease
  .DropdownBox-Container
    padding-top: 0
    top: 48px
.Site-UserProfileBar-UserData_opened
  .Icon
    transform: rotate(-180deg)
.Site-UserProfileBar-UserData
  cursor: pointer
  max-width: 140px
  display: flex
  align-items: center
  margin-left: 12px
  .UserProfileBar-UserName
    font-size: 16px
    margin-right: 8px
    font-weight: 600
