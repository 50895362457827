.ControllButtons
    width: 100%
    position: absolute
    bottom: 0
    left: 0
    height: 64px
    background-color: #fff

.ControllButtons-Container
    border-top: 1px var(--inputBorder) solid
    display: flex
    align-items: center
    height: 64px

    button
        &:not(:last-child)
            margin-right: 24px