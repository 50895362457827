.AudioPlayer, .PendingAudioFile
  background-color: var(--borderColor)
  width: 100%
  height: 32px
  border-radius: 4px
  padding: 8px
  display: flex
  align-items: center
  .AudioPlayer-Timer
    margin: 0px 12px
    &:last-child
      margin-right: 0
.PendingAudioFile
  font-size: 10px
  display: flex
  justify-content: center
  color: var(--descriptionDark)

.AudioPlayer-ActionButton
  width: 16px
  height: 16px
  border-radius: 4px
  background: var(--descriptionDark)
  border: 0
  color: #fff
  font-size: 6px
  display: flex
  justify-content: center
  align-items: center
  outline: none
  appearance: none
  svg
    color: #fff !important

.AudioPlayer-ActionButton_theme_unactive
  font-size: 8px
  &:hover
    background: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    color: #fff

.AudioPlayer-ActionButton_theme_active
  background: var(--accentColor)
  box-shadow: var(--whiteBoxShadow)
  color: #fff

.AudioPlayer-Timer
  min-width: 24px
  text-align: right
  font-size: 10px
  color: var(--descriptionDark)
  font-weight: 600
.AudioPlayer-CustomButtons
  margin-left: 8px
