.TagCloud
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: min-content
  padding: 4px
  cursor: pointer
.TagCloud-OnFocuse
  .TagCloud
    background-color: var(--backgroundColor)
    border-radius: 8px
    .TagCloud-InputContainer
      height: 22px
      opacity: 1
    .TagComponent-CloseIcon
      display: flex !important
  .TagCloud-TagComponent
    padding: 0px 4px 0px 8px
.TagCloud-Container
  width: 100%
  display: flex
  flex-wrap: wrap
.TagCloud-EmptyTags
  background: none !important
  .TagCloud-InputContainer
    opacity: 1 !important
    height: 22px !important
  .TagCloud-Input
    border-bottom: 1px var(--inputBorder) solid
.TagCloud-DisableEdit
  .TagCloud-InputContainer
    display: none

.TagCloud-TagComponent
  font-size: 11px
  line-height: 11px
  background-color: #fff
  border: 1px var(--inputBorder) solid
  border-radius: 10px
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 0px 8px
  min-height: 22px
  margin-bottom: 8px
  &:not(:last-child)
    margin-right: 8px
  .TagComponent-Icon
    margin-right: 4px
    width: 12px
    height: 12px
    display: flex
    justify-content: center
    align-items: center
  .TagComponent-RemoveIcon
    width: 12px
    height: 12px
    border-radius: 100%
    background-color: rgba(255,255,255,0.8)
    display: flex
    justify-content: center
    align-items: center
    font-size: 7px
    flex-shrink: 0
    margin-left: 6px
    color: var(--blackColor)

.TagCloud-InputContainer
  width: 100%
  height: 0
  opacity: 0
  position: relative
.TagCloud-Input
  width: 100% !important
  border: 0px
  padding: 0px 8px
  padding-bottom: 4px
  height: 22px
  background: none

.TagCloud-Suggestions
  width: 100%
  position: absolute
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  top: 30px
  padding: 8px
  border-radius: 8px
  max-height: 300px
  overflow: auto
  z-index: 5
  span
    font-size: 11px
    height: 22px
    padding: 0px 8px
    border: 1px var(--inputBorder) solid
    border-radius: 10px
    display: inline-flex
    justify-content: center
    align-items: center
    background-color: #fff
    // mark
    //   background-color: #fff
    //   color: var(--accentColor)
  li
    padding: 4px 8px

  .TagCloud-SuggestionActive
    background-color: var(--accentColor)
