.TaskExpireDate-DatePickerField
  display: none !important

.NavElements-Item
  .react-datepicker-wrapper
    height: 0
    position: absolute

.TaskDatePicker
  position: absolute
  z-index: 3
  right: 0
  width: 480px
  top: 40px
  display: flex
  .NextTimePicker
    flex-shrink: 0
  .DatePicker
    position: relative
    .react-datepicker
      box-shadow: none
  .TaskDatePicker-Container
    margin-left: 8px
    flex-shrink: 0
    box-shadow: var(--whiteBoxShadow)
    background-color: #fff
    border-radius: 8px

  .TaskDatePicker-Inputs
    display: flex
    align-items: center
    margin: 8px 0px 16px 0px
    padding: 0px 8px
    .Field
      width: 107px
      margin-right: 16px
      margin-bottom: 0
    .Save
      width: 80px
      height: 24px
      background: var(--accentColor)
      color: #fff
      border-radius: 8px
      border: 0
      display: flex
      align-items: center
      justify-content: center

.TaskDatePicker-ButtonsContainer
  display: flex
  justify-content: center
  .Save
    height: 24px
    padding: 0px 8px
    background: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    border: 0px
    border-radius: 8px
    color: #fff
