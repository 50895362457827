.MembersManager-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
.MembersManager-UserInfo
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 16px
  .SceneContent-Header, .Field-Textarea
    grid-column: 1/-1
.MembersManager-UserSearchField
  position: relative
  .Field-Error
    bottom: -20px
    left: 0
