.TaskNav
  width: 168px
  flex-shrink: 0

  .Column-Title
    font-size: 12px
    font-weight: 700
    text-transform: uppercase
    color: var(--descriptionDark)
    margin-bottom: 8px
    &:not(:first-child)
      margin-top: 16px

  .NavOptions
    margin-top: 24px

  .NavElements
    .NavElements-Item
      padding: 8px
      background-color: #F2F4F6
      border-radius: 4px
      position: relative
      cursor: pointer
      &:not(:last-child)
        margin-bottom: 8px
    .NavElements-ItemContainer
      display: flex
      .Item-Icon
        width: 14px
        height: 14px
        display: flex
        justify-content: center
        align-items: center
        color: var(--descriptionDark)
        margin-top: 2px
      .Item-Label
        margin-left: 8px
