.DataCounters
  display: grid
  grid-column: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  grid-gap: 12px
.CountersLine
  display: flex
  font-size: 36px
  line-height: 36px
  font-weight: 700
.CountersLegend
  display: grid
  grid-gap: 4px
  grid-column: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  .DataCounters-LegendLine
    display: flex
    align-items: center
    font-size: 11px
    line-height: 11px
    .LegendMark
      display: block
      width: 8px
      height: 8px
      border-radius: 100%
      margin-right: 4px

.DataCounters-Counter_theme_default
  color: var(--descriptionLight)
.DataCounters-LegendLine_theme_default
  .LegendMark
    background-color: var(--descriptionLight)

.DataCounters-Counter_theme_positive
  color: var(--accentColor)
.DataCounters-LegendLine_theme_positive
  .LegendMark
    background-color: var(--accentColor)

.DataCounters-Counter_theme_negative
  color: var(--notyColor)
.DataCounters-LegendLine_theme_negative
  .LegendMark
    background-color: var(--notyColor)
