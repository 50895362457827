.ExtensionPage
  width: 900px
  .Submenu
    width: 100%
    position: relative
    top: 0
    left: 0
    background-color: #fff
    padding: 0
    &::after
      width: 100%
.ExtensionPage_theme_full_view
  width: 100%
  top: 0
  border-radius: 0px

.ExtensionPage-Image
  width: 100%
  height: 280px
  object-position: center center
  object-fit: cover
  border-radius: 12px
.ExtensionPage-Container
  display: grid
  grid-template-columns: 1fr 260px
  grid-gap: 16px
  align-items: start
.ExtensionPage-Content
  display: grid
  grid-template-columns: 1fr
  width: 100%
  grid-gap: 16px
  align-items: start
  grid-template-rows: min-content
  justify-items: center
.ExtensionPage-Info
  width: 260px
  background-color: var(--backgroundColor)
  border-radius: 8px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
  align-items: start
  grid-template-rows: 150px max-content
  .InfoImage
    width: 100%
    height: 150px
    object-fit: cover
    object-position: center center
    border-radius: 8px
    align-self: start
    display: block
  .ExtensionPage-SectionTitle
    font-size: 13px
    font-weight: 700
  .ExtensionTitle
    font-size: 16px
  .ExtensionPage-InfoBlock
    display: grid
    grid-template-columns: 1fr
    grid-gap: 4px
    width: 100%
  .InfoContainer
    padding: 0px 16px
    padding-bottom: 16px
    width: 100%
    display: grid
    grid-template-columns: 1fr
    grid-gap: 16px
  .ExtensionPage-Description
    font-size: 12px
    font-weight: 400
    color: var(--descriptionDark)
  .ExtensionPage-Counters
    display: grid
    grid-template-columns: max-content max-content
    grid-gap: 16px
    .Icon
      color: var(--descriptionDark)
