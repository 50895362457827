.Tabs
  .Tabs-Header
    display: inline-flex
    align-items: center
    position: relative
    z-index: 2
  .Tabs-Tab
    padding: 4px 8px
    background-color: var(--backgroundColor)
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    border: 1px var(--descriptionLight) solid
    cursor: pointer
    font-weight: 600
    .Icon
      margin-right: 8px
    &:not(:last-child)
      margin-right: 8px
    &:hover
      background-color: #fff
      border-bottom: 1px solid #fff
      color: var(--accentColor)
  .Tabs-Tab_theme_active
    background-color: #fff
    border-bottom: 1px solid #fff
    color: var(--accentColor)

  .Tabs-Body
    padding: 8px
    border: 1px var(--inputBorder) solid
    border-radius: 8px
    border-top-left-radius: 0px
    position: relative
    z-index: 1
    margin-top: -1px
