.Field-DateRange
  position: relative
  input
    padding-left: 0
    padding-right: 0
  label
    left: 20px
  .TaskDatePicker
    width: auto
    left: 0
    top: 48px
