.ContactManager
  display: grid
  grid-gap: 16px
  grid-auto-rows: max-content
.ContactManager-Form
  display: grid
  grid-template-columns: repeat(2,1fr)
  grid-gap: 16px
  .SceneContent-Header, .Fieldset
    grid-column: 1/-1
  .Fieldset
    display: grid
    grid-template-columns: 1fr 16px
    grid-gap: 16px
    align-items: center
    .Fields
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 16px
