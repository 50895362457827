.TasksCounter
  display: flex

  .TaskCounterColumn
    &:not(:last-child)
      margin-right: 12px
      padding-right: 12px
      border-right: 1px var(--borderColor) solid
    .TaskCounterColumn-Label
      font-weight: 700
      color: var(--descriptionDark)
    .TaskCounterColumn-Counter
      font-size: 36px
      line-height: 36px
      font-weight: 700
      margin-top: 8px
  .TaskCounterColumn_theme_default
    color: var(--descriptionDark)
  .TaskCounterColumn_theme_positive
    color: var(--accentColor)
  .TaskCounterColumn_theme_negative
    color: var(--notyColor)
