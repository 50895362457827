.Site-PrivacyPolicy
  padding: 80px 0px
  .container
    display: grid
    grid-template-columns: 1fr
    grid-gap: 64px

.PrivacyPolicy-Container, .PrivacyPolicy-Attachment
  display: grid
  grid-gap: 16px
  grid-template-columns: 1fr
  h5
    font-size: 16px
    font-weight: 700
    margin: 0
  ul
    list-style-type: disc
    padding-left: 32px
