.DropdownBox-Container
  position: absolute
  width: 100%
  z-index: 2
  padding-top: 12px
  // right: -12px
  right: 0px

.DropdownBox
  padding: 16px 0px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  border-radius: 8px
  max-height: 300px
  overflow: auto
.DropdownBox-EmptyText
  color: var(--descriptionDark)
  text-align: center

.DropdownBox-Element
  position: relative
  list-style: none
  padding: 6px 8px
  font-size: 13px
  cursor: pointer
  color: var(--blackColor)
  text-align: left

  .Label
    font-size: 12px
    line-height: normal
    color: var(--blackColor)
    font-weight: 700
    text-align: left
    margin-bottom: 4px
    .SubLabel, .SubLabel-Gray
      font-size: 12px
      line-height: normal
      text-align: left
      font-weight: 500
      color: var(--blackColor)
    .SubLabel-Gray
      color: var(--descriptionDark)

  &:hover
    color: #fff
    background: var(--accentColor)
    .Label, .SubLabel, .SubLabel-Gray
      color: #fff
  &:after
    content: ''
    position: absolute
    width: calc(100% - 16px)
    background-color: var(--borderColor)
    height: 1px
    bottom: -1px
    left: 8px
  &:last-child
    &:after
      display: none

.DropdownBox-Element_theme_active
  color: #fff
  background: var(--accentColor)
  box-shadow: var(--whiteBoxShadow)
  .Label, .SubLabel, .SubLabel-Gray
    color: #fff

.DropdownBox
  .Field
    margin: 0px 8px 12px 8px !important
    svg
      transform: rotate(0deg)
  .EmptyMessage
    margin: 0
    text-align: center
    color: var(--labelColor)
