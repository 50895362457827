.PopUp
  width: 500px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  padding: 0px 16px 16px 16px
  position: absolute
  top: 64px
  left: 50%
  transform: translateX(-50%)
  border-radius: 8px

  .PopUp-Content
    padding-bottom: 64px
  .ControllButtons
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
  .ControllButtons-Container
    justify-content: center
  .SubmitFormButtons
    padding-bottom: 0
