.ActiveContactBlock
  width: 100%
  color: var(--descriptionDark)
  .Buttons
    margin-left: 20px
    display: flex
    button
      appearance: none
      outline: none
      &:not(:last-child)
        margin-right: 16px

.ActiveContactBlock-DataLine
  display: flex
  color: var(--descriptionDark)
  margin-bottom: 8px
  &:last-child
    margin-bottom: 0
  span
    margin-left: 8px
    font-size: 12px
    color: var(--blackColor)

.DataLine-Icon
  width: 12px
  height: 12px
  display: flex
  justify-content: center
  align-items: center
  margin-top: 2px

.SkeletonButtons
  margin-left: 36px
  span
    &:not(:last-child)
      margin-right: 8px
