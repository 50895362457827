.CallResultsPopUp
  background-color: #39393D

  .Header-Title
    color: #A7ACB0

  .Header-ButtonContainer
    padding: 0
    width: 16px
    height: 16px

    .IconicButton
      background-color: #4E4F53
      color: #A7ACB0

  .Field-Textarea-Autosize
    border: 0
    margin-bottom: 0
    textarea
      color: #fff
      min-height: 100px
      border-radius: 8px
      background-color: #4E4F53
      &::placeholder
        color: #A7ACB0

  .ControllButtons
    background-color: #39393D
    .ControllButtons-Container
      border-top: 1px #4E4F53 solid

    .Button_theme_active
      color: #fff !important
    .Button
      background-color: #4E4F53
      color: #A7ACB0
