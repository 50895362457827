.InfoLine
  display: flex
  flex-wrap: wrap
  font-size: 16px
  line-height: 26px
  color: var(--blackColor)
  .Bolder
    margin-right: 8px
    color: var(--descriptionDark)
  a
    color: var(--accentColor)
