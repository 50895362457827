.WhyWe
  margin-top: 64px
  padding-bottom: 80px
.WhyWe-Container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
.WhyWe-Text
  margin: 32px 0px
  text-align: center
