.SalesFunnel
  width: 100%
  display: grid
  grid-template-columns: 200px 1fr
  grid-gap: 16px
.SalesFunnel-Container
  display: flex
  align-items: center
  overflow-x: auto
  // .FunnelBlock
  //   &:not(:last-child)
  //     margin-right: 16px
  //   &:first-child
  //     margin-right: 0
