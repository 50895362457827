.SceneContent-TableContainer
  position: relative
.SceneContent-Table
  width: 100%

.SceneContent-Table
  tr
    position: relative
    border-bottom: 1px solid var(--borderColor)
    th
      height: 40px

.SceneContent-Table
  th
    padding: 4px 8px
    font-size: 12px
    font-weight: 600
    color: var(--descriptionDark)
.SceneContent-Table
  td
    padding: 4px 8px
    font-size: 12px
    line-height: 15px
    min-width: 0px
  tbody
    tr
      cursor: pointer
      // &:hover
      //   transform: scale(1)
      //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
      &:last-child
        border-bottom: 0
    .RemoveBoxShadow
      box-shadow: none !important
.OptionsButton-Container
  width: 20px
  height: 20px
  position: relative
  button
    font-size: 11px
    line-height: 20px

  .DropdownBox-Container
    top: 22px
    width: auto
    withe-space: nowrap
    min-width: 120px
    right: -12px

.SceneContent
  width: 100%
  padding: 0px 16px 24px 16px
  border-radius: 8px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)

.SceneContent_theme_empty
  background: none
  box-shadow: none
