.SideEvents-Container
  width: 300px !important
  bottom: 0 !important
  padding: 0 !important
.SideEvents_Event
  padding: 0 !important
  background: none !important
  box-shadow: var(--whiteObjectShadow)
  border-radius: 16px !important
  margin-bottom: 16px !important
.SideEvents-ProgressBar
  background: #4E4F53 !important
