.Tasks-Container
  width: calc(100% + 16px)
  height: calc(100vh - 128px)
  overflow-y: auto
  overflow-x: hidden
  margin-left: -16px
  .infinite-scroll-component__outerdiv
    width: 100%
    height: 100%
  .infinite-scroll-component
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 16px
    padding: 0px 16px
