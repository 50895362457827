.UsersPicker-Container
  position: relative
.UsersPicker
  padding: 8px 0px
  font-size: 11px
  text-transform: uppercase
  font-weight: 600
  color: var(--descriptionDark)
  position: relative
  display: flex
  align-items: center
  cursor: pointer
  &:hover
    color: var(--blackColor)
    &::after
      content: ''
      display: block
      width: 100%
      height: 2px
      border-top-left-radius: 2px
      border-top-right-radius: 2px
      background: var(--accentColor)
      position: absolute
      bottom: 0
      box-shadow: var(--whiteBoxShadow)
  .UsersPicker-Arrow
    font-size: 14px
    margin-left: 8px
    margin-top: -2px

.UsersPicker_status_active
  color: var(--blackColor)
  &::after
    content: ''
    display: block
    width: 100%
    height: 2px
    border-top-left-radius: 2px
    border-top-right-radius: 2px
    background: var(--accentColor)
    position: absolute
    bottom: 0
    box-shadow: var(--whiteBoxShadow)
