.Status
  padding: 4px 8px
  font-size: 10px
  border-radius: 4px

.Status_theme_active
  background-color: #BEF0C7
  color: var(--accentColor)
.Status_theme_archive
  background-color: #E9E9E9
  color: #A7ACB0
