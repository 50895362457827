.SceneFilters
  width: 100%
  display: flex
  justify-content: flex-end

.SceneContent-Header-ActiveFilter
  height: 20px
  padding-left: 8px
  background-color: var(--descriptionLight)
  border-radius: 24px
  font-size: 10px
  text-transform: uppercase
  display: inline-flex
  align-items: center
  color: #ffffff
  font-weight: 600
  &:not(:last-child)
    margin-right: 8px
  .FilterLabel
    margin-right: 4px
    font-weight: 400

  .FilterClose-ButtonContainer
    .IconicButton
      width: 12px
      height: 12px
      padding: 0
      margin-left: 4px
      margin-right: 4px
      outline: none
    svg
      font-size: 8px

.SceneContent-Header-ActiveFilters-Counter
  width: 20px
  height: 20px
  display: flex
  justify-content: center
  align-items: center
  font-size: 10px
  background-color: var(--descriptionLight)
  color: #ffffff
  font-weight: 600
  border-radius: 100%
