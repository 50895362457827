.CallStatus
  display: flex
  color: #A7ACB0
  font-size: 11px
  padding-right: 32px
  max-width: 240px
  img
    margin-top: 4px
    margin-right: 12px

.CallStatus-Container
  p
    margin: 0

.CallStatus-Status_theme_waiting
  color: #FFC674

.CallStatus-Status_theme_notify
  color: #F34375

.CallStatus-Status_theme_active
  color: var(--accentColor)
