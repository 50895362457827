.UsersTopRating-Widget
  justify-content: flex-start
.UsersTopRating
  display: grid
  grid-column: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  grid-gap: 12px

.RatingLine
  display: flex
  padding-bottom: 12px
  border-bottom: 1px var(--borderColor) solid
  align-items: flex-start
  .UserBlock
    width: 80px
    display: flex
    flex-shrink: 0
    flex-direction: column
    align-items: center
    justify-content: center
  .DataBlock
    margin-left: 12px
    width: 100%
    .UserName
      font-size: 12px
      margin-bottom: 12px
    .ProgressBar
      width: 100%
      background-color: var(--borderColor)
      height: 4px
      border-radius: 4px
      .ProgressMarker
        display: block
        background-color: var(--accentColor)
        height: 4px
        border-radius: 4px
    .Counters
      display: flex
      flex-wrap: wrap
    .CounterBlock
      margin-top: 12px
      .CounterBlock-Counter
        font-size: 11px
        font-weight: 700
      .Counter-CalcType
        font-size: 9px
        // font-size: 12px
      .CounterBlock-Label
        font-size: 10px
        color: var(--descriptionDark)
        font-weight: 600
      &:not(:last-child)
        padding-right: 8px
        border-right: 1px var(--borderColor) solid
        margin-right: 8px

  .TopPlaceMarker
    font-size: 11px
    width: 100%
    padding: 2px 4px
    font-weight: 700
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    margin-top: 12px
  .TopPlaceMarker_theme_gold
    background-color: #F8E00B
  .TopPlaceMarker_theme_silver
    background-color: #E5E5E5
  .TopPlaceMarker_theme_bronze
    background-color: #FAC335

.RatingLine-Winner
  .DataBlock
    .UserName
      font-weight: 700
      text-transform: uppercase

    .ProgressBar
      height: 6px
      border-radius: 6px
      .ProgressMarker
        height: 6px
        border-radius: 6px
    .CounterBlock
      .CounterBlock-Counter
        font-size: 16px
      .Counter-CalcType
        font-size: 11px

.RatingLine-Top
  .DataBlock
    .UserName
      font-size: 13px
      font-weight: 600
    .CounterBlock
      .CounterBlock-Counter
        font-size: 14px
      .Counter-CalcType
        font-size: 11px
