.ImageEditor
  position: fixed
  left: 50%
  top: 50%
  margin-left: -400px
  margin-top: -200px
  .tui-image-editor-header
    display: none
  .tui-image-editor-container
    border-radius: 12px
  .tui-image-editor-menu
    height: 64px !important
    display: flex !important
    align-items: center
    padding-left: 34px !important
  .ImageEditor-CloseButton
    position: absolute
    top: 16px
    right: 14px
    z-index: 3
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    background-color: #4E4F53
    color: #A7ACB0
    border-radius: 100%
  .ImageEditor-SaveButton
    position: absolute
    bottom: 16px
    right: 48px
    z-index: 3
