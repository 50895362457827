@import '_mixins/font-face'

//Regular fonts
+font-face('ProximaNova', './fonts/ProximaNova-Thin', 200)
+font-face('ProximaNova', './fonts/ProximaNova-Light', 300)
+font-face('ProximaNova', './fonts/ProximaNova-Regular', 400)
+font-face('ProximaNova', './fonts/ProximaNova-Semibold', 600)
+font-face('ProximaNova', './fonts/ProximaNova-Bold', 700)
+font-face('ProximaNova', './fonts/ProximaNova-Extrabld', 800)
+font-face('ProximaNova', './fonts/ProximaNova-Black', 900)

//Italic fonts
+font-face('ProximaNovaIt', './fonts/ProximaNova-ThinIt', 200)
+font-face('ProximaNovaIt', './fonts/ProximaNova-LightIt', 300)
+font-face('ProximaNovaIt', './fonts/ProximaNova-RegularIt', 400)
+font-face('ProximaNovaIt', './fonts/ProximaNova-SemiboldIt', 600)
+font-face('ProximaNovaIt', './fonts/ProximaNova-BoldIt', 700)
+font-face('ProximaNovaIt', './fonts/ProximaNova-ExtrabldIt', 800)
+font-face('ProximaNovaIt', './fonts/ProximaNova-BlackIt', 900)