.ModuleSpinner-Container, .PopupSpinner-Container
    width: 100%
    height: 32px
    text-align: center
.PopupSpinner-Container
    height: 24px

.LayoutSpinner-Container
    width: 100vw
    height: 100vh
    background-color: var(--backgroundColor)
    position: fixed
    display: flex
    justify-content: center