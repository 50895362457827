.DropdownProfile
    padding: 0px 16px
    white-space: nowrap
    width: 100%

.DropdownProfile-BriefLine
    display: flex
    justify-content: space-between

.BriefLine-Name
    margin-left: 8px
    font-size: 12px

    p
        font-weight: 700
        margin-bottom: 0
        &:last-child
            color: var(--labelColor)
            font-weight: 500

.BriefLine-SettingsButton
    font-size: 14px
    color: var(--labelColor)
    line-height: 42px

.DropdownProfile-Content
    margin-top: 16px
    .Content-Header
        display: flex
        align-items: center
        font-size: 14px
        color: var(--labelColor)
        padding-top: 12px
        border-top: 1px var(--borderColor) solid
        span
            font-size: 12px
            font-weight: 500
            margin-left: 8px

        svg
            width: 14px

    .Content-Element
        padding-left: 22px
        font-weight: 600
        margin-top: 8px
        line-height: 14px
        cursor: pointer