.StatusMessage
  padding: 4px 16px
  min-height: 36px
  display: flex
  align-items: center
  border-radius: 12px

.StatusMessage_theme_success
  background: var(--successColor)
  color: var(--accentColor)
  border-left: 2px var(--accentColor) solid

.StatusMessage_theme_fail
  background: var(--failColor)
  color: var(--notyColor)
  border-left: 2px var(--notyColor) solid
