.DropdownMarker
    width: 12px
    height: 12px
    font-size: 8px
    line-height: 14px
    border-radius: 100%
    background: var(--accentGradient)
    text-align: center
    box-shadow: var(--accentBoxShadow)
    cursor: pointer
    &:hover
        box-shadow: none