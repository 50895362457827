.SideNavSkeleton
  width: 64px
  height: 72px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-bottom: 16px
  .SideNavSkeleton-Title
    width: 42px
    margin-top: 8px

.SideNav-Item
  width: 64px
  height: 72px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-bottom: 16px
  cursor: pointer

  &:last-child
    margin-bottom: 0
  &:hover
    text-decoration: none
    .SideNav-Item_Icon
      border-radius: 100%
      background: var(--accentColor)
      color: #ffffff
      box-shadow: var(--whiteBoxShadow)

.SideNav-Item_theme_active
  .SideNav-Item_Icon
    border-radius: 100%
    background: var(--accentColor)
    color: #ffffff
    box-shadow: var(--whiteBoxShadow)

.SideNav-Item_Icon
  width: 36px
  height: 36px
  text-align: center
  font-size: 18px
  line-height: 36px
  margin-bottom: 4px
  color: var(--descriptionDark)
  position: relative
  .NotificationsMarker
    top: -4px
    right: -4px

.SideNav-Item_Title
  font-size: 10px
  text-align: center
  color: var(--descriptionDark)
  line-height: 12px
