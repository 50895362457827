.NavPanel
  width: 248px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 8px
  font-size: 13px
  line-height: 16px
  font-weight: 600
  grid-template-rows: min-content
  align-self: start
  .NavPanel-Block
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: min-content
    align-self: start
  .Header-Title
    text-transform: uppercase
    font-size: 12px
  .NavPanel-Item
    display: flex
    color: var(--descriptionDark)
    padding: 12px
    border-bottom: 1px var(--descriptionLight) solid
    cursor: pointer
    align-self: start
    &:last-child
      border-bottom: 0
    .NavItem-Icon
      width: 16px
      height: 16px
      margin-right: 12px
      display: flex
      justify-content: center
      align-items: center
    &:hover
      background-color: var(--accentColor)
      color: #fff
  .NavPanel-Item_theme_active
    background-color: var(--accentColor)
    color: #fff
