.EstateObjectTile
  display: grid
  grid-template-columns: 64px 1fr
  grid-gap: 16px
  align-items: center
  .EstateImage
    width: 64px
    height: 64px
  .EstateObjectTile-Body
    display: grid
    grid-template-columns: 1fr
    grid-gap: 4px
  .EstateObjectTile-EstateType
    font-weight: 700
.RowDataColumn
  display: grid
  grid-template-columns: 1fr
  grid-gap: 4px
.Skeleton-Responsible
  display: grid
  grid-template-columns: 24px 1fr
  grid-gap: 8px
  align-items: center
.Estatelist
  .InBaseCounter-Title
    font-size: 12px
