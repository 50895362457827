.SideScene
  width: 100%
  max-width: 700px
  height: 100vh
  background-color: #fff
  position: fixed
  top: 0
  right: 0
  box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.07)
  padding: 0px 16px
  z-index: 6
  display: flex
  flex-direction: column

.SideScene-Header
  height: 64px
  display: flex
  align-items: center

.SideScene-Body
  height: 100%

.SideScene-CloseContainer
  width: 24px
  height: 24px
  margin-right: 16px
  button
    font-size: 12px
    color: var(--defaultText)
