.Office-InfoBlocks
  display: flex
  margin: 8px 0px
  .OfficeInfoBlock
    width: auto
    margin-right: 16px
    height: auto
.OfficeInfo-AddInfo
  width: 120px
  height: auto
  background-color: var(--backgroundColor)
  display: flex
  justify-content: center
  flex-shrink: 0
  border-radius: 8px
  color: var(--sideLinks)
  cursor: pointer
  padding: 8px
  svg
    margin-top: 4px
    margin-right: 8px
