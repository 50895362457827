.Task-Comments-Container

.SmartInput
  display: flex
  .TypeSelect
    width: 100%
    & > *
      height: auto !important
      line-height: normal
      outline: none
    button
      position: relative
      text-decoration: underline
      color: var(--accentColor)

  .SmartInput-FieldContainer
    background-color: #fff
    padding: 12px
    box-shadow: var(--whiteBoxShadow)
    margin-left: 12px
    width: 100%
    border-radius: 8px
    border-top-left-radius: 0px
  .Buttons-Container
    .Button
      &:not(:last-child)
        margin-right: 8px

.Task-Comment
  display: flex
  margin-bottom: 12px
  .Comment
    margin-left: 8px
    padding: 8px
    background-color: var(--borderColor)
    border-radius: 8px
    border-top-left-radius: 0px
    .Comment-Name
      font-size: 13px
      font-weight: 700
    .Comment-Date
      margin-bottom: 4px
      font-weight: 500
      color: var(--sideLinks)

  .Comment_theme_highlighted
    background-color: var(--borderColor)
