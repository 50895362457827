.ProfitTag
  display: flex
  align-items: center
.ProfitTag-Indicator
  width: 16px
  height: 16px
  border-radius: 100%
  display: flex
  align-items: center
  justify-content: center
  transform: rotate(45deg)
  margin-right: 4px
  .Icon
    font-size: 10px
.ProfitTag-Value
  font-size: 11px
  line-height: 11px

.ProfitTag_theme_positive
  .ProfitTag-Indicator
    background-color: #BEF0C7
    color: var(--accentColor)
  .ProfitTag-Value
    color: var(--accentColor)
.ProfitTag_theme_negative
  .ProfitTag-Indicator
    background-color: #FDD0DD
    color: var(--notyColor)
  .ProfitTag-Value
    color: var(--notyColor)
