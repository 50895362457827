.Site-CookieNotification
  width: calc(100% - 32px)
  margin-left: 16px
  padding: 16px 24px
  background-color: rgba(0,0,0, 0.8)
  position: sticky
  bottom: 16px
  border-radius: 16px
  .CookieNotification-Container
    display: grid
    grid-template-columns: 64px 1fr max-content
    grid-gap: 16px
    align-items: center
  .CookieNotification-Icon
    width: 64px
    align-self: self-start
  .CookieNotification-Text
    color: #fff
    font-size: 12px
    line-height: 22px
    a
      color: var(--accentColor)
      &:hover
        text-decoration: underline !important
  .CookieNotification-Title
    font-size: 16px
    font-weight: 600
    margin-bottom: 8px

@media screen and ( max-width: 767px )
  .Site-CookieNotification
    padding: 16px
    .CookieNotification-Container
      grid-template-columns: 64px 1fr
      .CookieNotification-Button
        grid-column: 1/-1
        display: flex
        justify-content: center
