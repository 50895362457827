.OptionsList
  padding: 0px 8px

.OptionsList-Element
  width: 100%
  padding: 6px 0px
  display: flex
  align-items: center
  min-height: 30px
  border-bottom: 1px solid var(--borderColor)
  transition: all .2s ease
  text-transform: none
  font-weight: 400
  color: var(--blackColor)
  cursor: pointer
  span
    font-size: 12px
    line-height: 16px
  &:hover
    border-bottom: 1px solid var(--accentColor)
    .OptionsList-ElementIcon
      color: var(--accentColor)
  &:last-child
    border-bottom: 0

.OptionsList-ElementIcon
  width: 20px
  height: 20px
  flex-shrink: 0
  font-size: 12px
  line-height: 20px
  margin-right: 8px
  color: var(--descriptionDark)
  display: flex
  justify-content: center
  align-items: center
