.Task-Comments-Container

.Task-Comment-Input
  display: flex
  .Task-Comment-InputField
    margin-left: 8px
    width: 100%
    textarea
      width: 100%
      border-radius: 8px
      background-color: var(--borderColor)
      border-top-left-radius: 0px
      border: 0px
      padding: 8px
      min-height: 36px
      max-height: 100px
      margin-bottom: 4px
      &::placeholder
        color: var(--sideLinks)
        font-weight: 500

.Task-Comment
  display: flex
  margin-bottom: 12px
  .Comment
    margin-left: 8px
    padding: 8px
    background-color: var(--borderColor)
    border-radius: 8px
    border-top-left-radius: 0px
    .Comment-Name
      font-size: 13px
      font-weight: 700
    .Comment-Date
      margin-bottom: 4px
      font-weight: 500
      color: var(--sideLinks)
    .Comment-Text
      display: flex
      .Text-Container
        margin-right: 8px
        width: 100%
      .Text-Controlls
        margin-top: 2px
        flex-shrink: 0
        width: 24px
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        font-size: 13px
        button
          color: var(--sideLinks)
          background: none
          padding: 0
          margin: 0
          border: 0
          appearance: none
          outline: none
  .Comment_theme_result
    background-color: #BEF0C7
    color: #029E1E
    padding: 2px 4px
    border-radius: 4px
    margin-left: 8px

  .Comment_theme_highlighted
    background-color: var(--borderColor)
