.OptionsSkeleton
  display: grid
  grid-template-columns: 1fr
  grid-gap: 4px
  width: calc(100% - 16px)
  padding: 8px 0px
  margin: 0px 8px
  span
    height: 6px
  &:not(:last-child)
    border-bottom: 1px var(--inputBorder) solid
