.MotivationPlan
  display: grid
  grid-gap: 6px
  grid-column: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  .DataLine
    display: flex
    align-items: center
    justify-content: space-between
    font-size: 12px
    font-weight: 600
    color: var(--labelColor)
  .DataLine-Value
    color: var(--defaultText)
