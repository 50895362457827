.PinnedMember-Container
  display: grid
  width: 100%
  grid-template-columns: 56px 1fr max-content
  align-items: center
  grid-gap: 16px
  .Avatar
    width: 56px
    height: 56px
    position: relative
    cursor: pointer
    &:hover
      .Avatar-ChangeScene
        display: flex
    .Avatar-ChangeScene
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,0.3)
      position: absolute
      top: 0
      left: 0
      border-radius: 100%
      display: none
      justify-content: center
      align-items: center
      font-size: 18px
      color: #fff
      z-index: 2
      cursor: pointer
    .Field
      position: absolute
      width: 56px
      height: 56px
      top: 0
      left: 0
      opacity: 0
      z-index: 3
      cursor: pointer
      &::before
        .Avatar-ChangeScene
          display: flex

  .UserSkeletonData
    width: 100%
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: repeat(2, 8px)
    grid-gap: 8px
  .UserInviteButton
    min-width: 80px
    height: 32px
  .UserData-Name
    font-weight: 700
    font-size: 13px
  .UserData-Email
    color: var(--labelColor)

.PinnedMember
  .RemoveAvatarButton
    width: 56px
    text-align: center
    margin-top: 12px
