.Task-PopUp
  width: 768px
  padding: 12px 16px 32px 16px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  position: absolute
  top: 120px
  left: 0
  right: 0
  margin: auto
  border-radius: 8px
  .Task-SpinnerContainer
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: -20px

  .Task-Icon-Container
    width: 24px
    height: 24px
    flex-shrink: 0

  .Task-Header
    display: flex
    align-items: flex-start
    .Header-Icon
      color: var(--descriptionDark)
      margin-top: 4px
    .Task-Title
      font-size: 14px
      font-weight: 700
      margin: 0px 6px
      width: 100%
      .Task-Title-Field
        padding-left: 6px
        padding-right: 6px
        width: 100%
        border: 1px #fff solid
        &:focus
          border: 1px var(--accentColor) solid
          border-radius: 4px
          box-shadow: var(--whiteBoxShadow)

      .Task-ColumnName
        font-size: 10px
        color: var(--descriptionDark)
        font-weight: 500
        text-transform: uppercase
        padding: 0px 6px
        span
          text-decoration: underline

  .Task-Body-Container
    display: flex
    margin-top: 24px
    .Body
      width: 100%
      margin-right: 16px

    .Body-Header
      .Col-Body
        display: flex
        .UserAvatar
          margin-right: 4px
        .AllMembersTag
          margin-right: 8px
          padding: 4px 6px
          background-color: var(--borderColor)
          font-size: 12px
          color: var(--descriptionDark)
          border-radius: 4px
        .ActiveContactBlock
          padding: 8px
          // background-color: var(--borderColor)
          border-radius: 8px
          border: 1px var(--borderColor) solid
      .Task-Term
        height: 24px
        padding: 0 8px
        display: flex
        align-items: center
      .Task-Tags
        height: 24px

    .Body-Block
      width: 100%

      .Block-Title
        display: flex
        margin-bottom: 16px
        svg
          margin-top: 4px
          color: var(--descriptionDark)
          flex-shrink: 0
        span
          font-size: 12px
          text-transform: uppercase
          color: var(--descriptionDark)
          font-weight: 700
          margin-left: 12px

      .Task-Description
        width: 100%
        padding-left: 28px
        .Task-Description-Field
          width: 100%
          min-height: 50px
          border-radius: 8px
          background-color: #F2F4F6
          font-size: 13px
          border: 1px var(--backgroundColor) solid
          padding: 8px
          margin-bottom: 4px
          height: auto !important
          &:focus
            border: 1px var(--accentColor) solid
            box-shadow: var(--whiteBoxShadow)
            background-color: #fff
            min-height: 100px

          &::placeholder
            color: var(--descriptionLight)

    .Body-Header
      display: flex
      margin-left: 28px
      flex-wrap: wrap
      .Header-Col
        margin-bottom: 24px
        &:not(:last-child)
          margin-right: 24px
        .Title
          font-size: 12px
          font-weight: 700
          color: var(--descriptionDark)
          text-transform: uppercase
          margin-bottom: 8px
