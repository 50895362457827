.Site-CrmRender
  display: flex
  justify-content: center
  margin-top: -240px
  width: 100%

  img
    width: 100%
    max-width: 1169px
    object-fit: cover
    object-position: top center
