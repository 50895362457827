.Roles-Module
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
.Roles-ProfilesGrid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr))
  grid-gap: 16px
.Roles-TextHeader
  width: 236px
  font-size: 12px
  color: var(--descriptionDark)
  text-transform: uppercase
  font-weight: 700
  vertical-align: bottom
.Roles-SectionTitle
  background-color: var(--backgroundColor)
  text-transform: uppercase
  p
    padding: 8px 4px
    font-weight: 600
.Roles-Container
  display: grid
  grid-template-columns: 248px 1fr
  grid-gap: 16px
  .NavPanel
    padding-right: 16px
    border-right: 1px var(--descriptionLight) solid
.Roles-Content
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  grid-gap: 16px
  .Content-Settings
.Roles-SettingsRow
  display: grid
  grid-template-columns: 150px 1fr
  grid-gap: 16px
  padding: 8px 0px
  border-bottom: 1px var(--descriptionLight) solid
  &:last-child
    border-bottom: 0
