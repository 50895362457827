.Site-AboutEstate
  padding: 80px 0px
  background-color: var(--backgroundColor)
  background-repeat: no-repeat, no-repeat
  background-position: top left, top right
.Site-AboutEstate-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 80px
  margin-top: 64px
  .InfoBlock
    display: grid
    align-items: center
    grid-gap: 64px
  .InfoBlock-Content
    display: grid
    grid-template-columns: 1fr
    grid-gap: 24px
  .InfoBlock_renderPostion_left
    grid-template-columns: max-content 1fr
    .InfonBlock-Render
      order: 0
    .InfoBlock-Content
      order: 1
  .InfoBlock_renderPostion_right
    grid-template-columns: 1fr max-content
    .InfonBlock-Render
      order: 1
    .InfoBlock-Content
      order: 0

@media screen and ( max-width: 1200px )
  .Site-AboutEstate-Container
    .InfoBlock_renderPostion_left, .InfoBlock_renderPostion_right
      grid-template-columns: 1fr
      .InfonBlock-Render
        order: 1
        justify-self: center
      .InfoBlock-Content
        order: 0
      .InfoBlock-Title, .InfoBlock-Description
        text-align: center
@media screen and ( max-width: 991px )
  .Site-AboutEstate-Container
    .InfoBlock_renderPostion_left, .InfoBlock_renderPostion_right
      .InfonBlock-Render
        max-width: 720px

@media screen and ( max-width: 767px )
  .Site-AboutEstate-Container
    .InfoBlock_renderPostion_left, .InfoBlock_renderPostion_right
      .InfonBlock-Render
        max-width: 540px

@media screen and ( max-width: 575px )
  .Site-AboutEstate-Container
    .InfoBlock_renderPostion_left, .InfoBlock_renderPostion_right
      .InfonBlock-Render
        max-width: 100%
