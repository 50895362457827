.MotivationManager-Container
  display: grid
  grid-column: 1fr
  grid-gap: 24px
.MotivationManager-FormContainer
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 16px
  .Field
    margin-bottom: 0

.MotivationManager-RulesContainer
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px

.MotivationManager-RuleConstructorLine
  display: flex
  align-items: center
  .Field
    width: 100%
    margin: 0
    margin-left: 16px
  .Field-HtmlContent
    margin-left: 16px
    text-transform: uppercase
    font-size: 10px
    color: var(--labelColor)
    font-weight: 600
    margin-top: 8px
