.IL2hGczCtAL9B1Tua2Th
  .CategoryPrice
    display: grid
    grid-template-columns: 1fr
    grid-gap: 24px
  .CategoryPrice-PopUp-Container
    display: grid
    grid-template-columns: 1fr
    grid-gap: 24px
  .Backdrop
    width: 100% !important
