.PMC-Header-Top
  background-color: var(--backgroundColor)
  font-weight: 600
  color: var(--defaultText) !important
  height: 32px !important
.PMC-Header-Last
  th
    border-bottom: 1px var(--inputBorder) solid

.PMC-Header-Top-Devider
  border-right: 1px var(--inputBorder) solid
  &:last-child
    border-right: 0

.ParamsManagerConfigurator-Container
  overflow-x: auto
  height: 100%
  position: relative
  table
    border-collapse: separate
    border-spacing: 0
    td
      border-bottom: 1px var(--borderColor) solid
    th
      position: sticky
      top: 0

  .StickyColumnLeft
    position: sticky
    left: 0
    z-index: 3
  .StickyColumnRight
    border-left: 1px var(--inputBorder) solid
    position: sticky
    right: 0
    z-index: 3
  .DropdownBox-Triangle
    left: 7px
  .DropdownBox-Container
    min-width: 140px !important
.PMC-Text-Center
  text-align: center
.PMC-WhiteBox
  background-color: #fff
.PMC-LabelsColumn
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  color: var(--labelColor)
  height: 100%
  font-weight: 500
  span
    flex-shrink: 0
    display: flex
    align-items: center
    font-size: 12px
    height: 12px
    line-height: 12px
    &:not(:last-child)
      margin-bottom: 8px

.PMC-Param, .PMC-Category
  min-width: 170px
  display: flex
  align-items: center
  .OptionsButton
    margin-right: 8px
.PMC-Category
  .PMC-ChangePosControlls
    .Icon
      color: var(--sideLinks)
.PMC-ParamDescription
  .ParamType
    color: var(--labelColor)
.PMC-ActiveRow
  z-index: 4
  .StickyColumnLeft
    z-index: 4
.PMC-ChangePosControlls
  display: flex
  margin-left: auto
  padding-left: 8px
  justify-content: center
  color: var(--labelColor)
  .Icon
    &:first-child
      margin-right: 4px
    &:hover
      color: var(--defaultText)
.PMC-ChangePosition_disabled
  color: var(--borderColor) !important
