.IconicButton
  width: 100%
  height: 100%
  border-radius: 100%
  border: 0
  font-size: 18px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

.IconicButton_theme_main
  background: var(--accentColor)
  box-shadow: var(--whiteBoxShadow)
  color: #ffffff

.IconicButton_theme_remove
  background: var(--notyColor)
  box-shadow: var(--notyBoxShadow)
  color: #ffffff

.IconicButton_theme_basic, .IconicButton_theme_options
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07)
  background-color: #fff
  color: var(--descriptionDark)
  &:hover
    background-color: var(--descriptionLight)
    color: #fff

.IconicButton_theme_basic_pushed
  background-color: var(--pushedButton)
  color: #fff

.IconicButton_theme_options
  // color: var(--accentColor)
  transition: all 0.2s ease

  &:hover
    background-color: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    color: #fff

    &:active
      box-shadow: none
