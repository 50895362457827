.DealContact
.DealContact-Header
  width: 100%
  display: flex
  align-items: center
.DealContact-Marker
  width: 16px
  height: 16px
  flex-shrink: 0
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  margin-right: 12px
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  .Marker-Dot
    width: 8px
    height: 8px
    background-color: var(--descriptionLight)
    border-radius: 100%
.DealContact-Marker_theme_active
  .Marker-Dot
    background-color: var(--accentColor)
.DealContact-Body
  display: grid
  grid-template-columns: 1fr
  grid-gap: 8px
  padding-left: 28px
.DealContact-AddBlock
  display: grid
  grid-gap: 16px
.AddDealContact
  width: 100%
  padding: 8px 16px
  background-color: var(--backgroundColor)
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  transition: all 0.3s ease
  &:hover
    background-color: var(--descriptionLight)
    .AddDealContact-Icon
      background-color: #fff
      color: var(--descriptionDark)
    .AddDealContact-Title
      color: #fff

  .AddDealContact-Icon
    width: 24px
    height: 24px
    background-color: var(--descriptionLight)
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    flex-shrink: 0
  .AddDealContact-Title
    font-weight: 600
    margin-left: 8px
    text-transform: uppercase
    font-size: 10px
    color: var(--descriptionDark)
