.Phone
  position: relative
  display: grid
  grid-gap: 8px
  grid-template-columns: 70px minmax(0, 1fr)
  align-items: center

.CountrySelect
  input
    padding-top: 8px
    margin-left: 0 !important
  label
    left: 0
  .Field-Icon
    margin-left: 8px
.CountryFlag
  width: 12px
  height: 12px
  border-radius: 12px
  margin-top: 4px
  flex-shrink: 0

.PhoneInput
  .PhoneInputCountry
    display: none
