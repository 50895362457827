.ContactCard
  padding: 8px
  border-radius: 8px
.ContactCard-Header
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
.Header-Name
  display: flex
  align-items: center
  .ContactCard-Icon
    width: 24px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    background-color: var(--borderColor)
    border-radius: 100%
    color: var(--descriptionDark)
    margin-right: 8px
  .ContactName
    font-size: 13px
    font-weight: 600
.ContactCard-Header-Arrow
  font-size: 14px
  color: var(--descriptionDark)
  transition: all 0.3s ease

.ContactCard-Body
  padding: 8px 8px 8px 32px
  display: none
  grid-template-columns: max-content 1fr
  grid-gap: 4px
  align-items: center
  .DataTitle
    color: var(--descriptionDark)
  .Body-Buttons
    margin-top: 8px
    grid-column: 1/-1
    display: flex
    align-items: center
    justify-content: space-between
  .ContactCard-Options
    .Button
      &:not(:last-child)
        margin-right: 8px
.ContactCard-Body-SectionTitle
  grid-column: 1/-1
  font-weight: 600
.ContactCard_status_opened
  background-color: var(--backgroundColor)
  .ContactCard-Icon
    background-color: var(--backgroundColor)
  .ContactCard-Header-Arrow
    transform: rotate(180deg)
  .ContactCard-Body
    display: grid
