.Task-Tags
  width: 100%
  display: flex
  flex-wrap: wrap
  .Tag
    font-size: 10px
    width: auto
    padding: 2px 4px
    border-radius: 4px
    display: flex
    align-items: center
    margin-right: 4px
    &:not(:last-child)
      margin-right: 4px
