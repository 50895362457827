.Site-Hero
  width: 100%
  background-color: var(--blackColor)
  background-position: bottom 80px right, bottom right
  background-repeat: no-repeat, no-repeat
  background-size: auto 373px, auto 80px
  display: flex
  align-items: center
  padding-top: 160px
  padding-bottom: 300px
  margin-top: -64px
.Hero-Offer-Container
  display: flex
  justify-content: center
.Hero-Offer
  width: 100%
  max-width: 800px
  display: flex
  align-items: center
  flex-direction: column
  text-align: center
  .Offer-Title
    width: 100%
    font-size: 42px
    line-height: 52px
    text-transform: uppercase
    font-weight: 600
    margin-bottom: 24px
    color: #fff
  .Offer-Description
    margin-bottom: 32px

@media screen and ( max-width: 1200px )
  .Hero-Offer
    .Offer-Title
      font-size: 36px
      line-height: 46px
@media screen and ( max-width: 576px )
  .Hero-Offer
    .Offer-Title
      font-size: 32px
      line-height: 42px
