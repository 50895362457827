.NotificationsMarker
  width: 14px
  height: 14px
  background: var(--notyGradient)
  border-radius: 100%
  font-size: 8px
  display: flex
  align-items: center
  position: absolute
  top: -8px
  right: -8px
  justify-content: center
  color: #fff
  box-shadow: var(--notyBoxShadow)

.NotificationsMarker_theme_adaptive
  width: auto
  border-radius: 8px
  padding: 0px 4px
