.Site-SocialSignInDevider
  display: grid
  grid-gap: 8px
  grid-template-columns: 1fr max-content 1fr
  align-items: center
  span
    display: block
    width: 100%
    height: 1px
    background-color: var(--descriptionDark)
.Site-SocialSignIn
  display: grid
  grid-gap: 24px
  grid-template-columns: 1fr
  .SocialSignInButton
    width: 100%
    background-color: #fff
    border: 2px var(--descriptionLight) solid
    border-radius: 16px
    display: flex
    justify-content: center
    align-items: center
    height: 52px
    font-weight: 700
    transition: all 0.3s ease
    img
      margin-right: 12px
    &:hover
      background-color: var(--descriptionLight)
    &:active
      border: 2px var(--blackColor) solid
      background-color: var(--blackColor)
      color: #fff
