.Site-Profile
  padding: 32px 0px 80px 0px
  background-color: var(--backgroundColor)

.Site-ProfileContainer
  display: grid
  grid-template-columns: max-content 1fr
  grid-gap: 24px
  .ProfileContainer-Data
    display: grid
    grid-template-columns: 1fr
    grid-auto-rows: max-content
    grid-gap: 24px
  .ProfileContainer-Body
    width: 100%
    padding: 24px
    background-color: #fff
    border-radius: 16px
.Site-ProfileTitle
  margin: 0
  padding: 0
