.Site-EditProfile
  .UserData-Name
    font-size: 18px
    font-weight: 600
  .UserData-Email
    font-size: 16px
    color: var(--descriptionDark)
  .EditProfile-SubmitButton
    grid-column: 1/-1
    display: flex
    justify-content: center
  .PinnedMember
    .UserAvatar
      box-shadow: none
      background-color: #EDEFF1
      color: var(--descriptionDark)
      svg
        font-size: 24px !important

.Site-EditProfile-Container
  margin-top: 24px
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 24px
  .SceneContent-Header, .Phone
    grid-column: 1/-1
    width: 100%
    height: auto
    .Header-Title
      font-size: 16px
  .StatusMessage
    grid-column: 1/-1
