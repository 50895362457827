.Site-CreateAccount
  width: 100%
  display: flex
  justify-content: center
.Site-CreateAccount-Denied
  width: 100%
  padding: 8px
  text-align: center
  background-color: var(--blackColor)
  color: #fff
  border-radius: 8px

  a
    color: var(--accentColor)
    &:hover
      text-decoration: underline !important
