.Backdrop
  width: calc(100% - var(--sideNavWidth))
  height: 100vh
  background-color: rgba(0, 0, 0, 0.1)
  position: fixed
  top: 0
  right: 0
  z-index: 5
  overflow: scroll
  padding-bottom: 32px

.Backdrop_theme_transparent
  background: none
