.OrganizationCard
  padding: 8px
  border-radius: 8px
.OrganizationCard-Header
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  .Header-Name
    display: flex
    align-items: center
    .OrganizationCard-Icon
      width: 24px
      height: 24px
      display: flex
      justify-content: center
      align-items: center
      background-color: var(--borderColor)
      border-radius: 100%
      color: var(--descriptionDark)
      margin-right: 8px
    .ContactName
      font-size: 13px
      font-weight: 600
.OrganizationCard-Header-Arrow
  font-size: 14px
  color: var(--descriptionDark)
  transition: all 0.3s ease

.OrganizationCard-Body
  padding: 8px 8px 8px 32px
  display: none
  grid-template-columns: max-content 1fr
  grid-gap: 4px
  align-items: center
  .DataTitle
    color: var(--descriptionDark)
  .Body-Buttons
    margin-top: 8px
    grid-column: 1/-1
    display: flex
    align-items: center
    justify-content: space-between
  .OrganizationCard-Options
    .Button
      &:not(:last-child)
        margin-right: 8px
.OrganizationCard-Body-SectionTitle
  grid-column: 1/-1
  font-weight: 600
.OrganizationCard_status_opened
  background-color: var(--backgroundColor)
  .OrganizationCard-Icon
    background-color: var(--backgroundColor)
  .OrganizationCard-Header-Arrow
    transform: rotate(180deg)
  .OrganizationCard-Body
    display: grid
