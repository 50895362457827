.SearchUsersResults
  background-color: var(--backgroundColor)
  padding: 8px 16px
  border-radius: 8px
  .EmptyMessage
    width: 100%
    display: flex
    justify-content: center
    color: var(--labelColor)

.UserResultRow
  padding: 4px 0px
  display: grid
  width: 100%
  grid-template-columns: 32px 1fr max-content
  align-items: center
  grid-gap: 16px
  .Avatar
    width: 32px
    height: 32px
  .UserSkeletonData
    width: 100%
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: repeat(2, 8px)
    grid-gap: 8px
  .UserInviteButton
    min-width: 80px
    height: 32px
  .UserData-Name
    font-weight: 700
    font-size: 13px
  .UserData-Email
    color: var(--labelColor)
