.Funnel-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
  flex-shrink: 0
.Funnel-Header
  display: flex
  justify-content: space-between
  align-items: center
.Funnel-CurrentStep
  font-weight: 600
  font-size: 13px
  color: var(--descriptionDark)
  span
    color: var(--blackColor)
.Funnel-Buttons
  .Button
    &:last-child
      margin-left: 16px
.Funnel-Steps
  width: 100%
  height: 28px
  background-color: var(--descriptionLight)
  border-radius: 8px
  display: grid
  .Funnel-Step
    &:first-child
      border-top-left-radius: 8px
      border-bottom-left-radius: 8px
    &:last-child
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
      .Funnel-Step-TriangleWhite, .Funnel-Step-TriangleColor
        display: none

.Funnel-Step
  position: relative
  padding: 0px 16px
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  cursor: pointer
  font-weight: 600
  width: 100%

.Funnel-Step-TriangleWhite
  width: 0
  height: 0
  border-style: solid
  border-width: 14px 0 14px 8px
  border-color: transparent transparent transparent #fff
  position: absolute
  top: 0
  right: -8px
  z-index: 1
.Funnel-Step-TriangleColor
  width: 0
  height: 0
  border-style: solid
  border-width: 14px 0 14px 8px
  border-color: transparent transparent transparent var(--descriptionLight)
  position: absolute
  top: 0
  right: -6px
  z-index: 2
.Funnel-Step_status_active, .Funnel-Step_status_passed
  background-color: var(--accentColor)
  color: #fff
  .Funnel-Step-TriangleColor
    border-color: transparent transparent transparent var(--accentColor)
.Funnel-Step_status_skipped
  text-decoration: line-through
.Funnel-Buttons
  display: flex
  align-items: center
.Funnel-Step_disabled
  cursor: not-allowed
