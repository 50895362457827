.EstatePhotoManager
  display: grid
  grid-gap: 16px
  grid-template-columns: 1fr
  height: calc(100% - 32px)
.EstatePhotoManager-PhotoGrid
  display: grid
  grid-gap: 16px
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr))
  grid-template-rows: 100px
  .DragAndDrop-Zone
    grid-column: 1/-1
.DragAndDrop-Zone
  width: 100%
  height: 100%
  min-height: 45px
  border-radius: 8px
  background-color: var(--backgroundColor)
  border: 1px var(--inputBorder) dashed
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 8px
  color: var(--labelColor)
  cursor: pointer
  outline: none
  appearance: none
  span
    color: var(--defaultText)
    text-decoration: underline
