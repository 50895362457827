.UserAvatar
  background-color: #fff
  border-radius: 100%
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07)
  text-align: center
  color: #fff
  position: relative
  flex-shrink: 0
  .UserAvatar_img
    width: 100%
    height: 100%
    border-radius: 100%
  img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: top center
    border-radius: 100%

.DeleteButton
  width: 100%
  height: 100%
  border-radius: 100%
  background-color: rgba(0, 0, 0, 0.4)
  top: 0px
  left: 0px
  position: absolute
  display: none
  align-items: center
  justify-content: center
  opacity: 0

.UserAvatar_theme_noavatar
  width: 100%
  height: 100%
  background-color: var(--descriptionLight)
  border-radius: 100%
  font-size: 42px
  display: flex
  align-items: center
  justify-content: center

.UserAvatar_theme_delete
  cursor: pointer
  &:hover
    .DeleteButton
      display: flex
      opacity: 1
