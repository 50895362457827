.SalesFunnelControlls
  width: 200px
  border-right: 1px var(--inputBorder) solid
  display: flex
  align-items: center
  height: 100%

.SalesFunnelControlls-Container
  display: flex
  flex-direction: column
  padding: 16px
  max-height: 240px
  overflow-y: auto
  .ControllItem
    text-transform: uppercase
    color: var(--descriptionDark)
    cursor: pointer
    font-size: 11px
    font-weight: 600
    &:not(:last-child)
      margin-bottom: 12px
    span
      display: inline-block
      padding-bottom: 4px
      border-bottom: 2px var(--backgroundColor) solid
    &:hover
      color: var(--blackColor)
      span
        border-bottom: 2px var(--accentColor) solid

  .ControllItem_theme_active
    color: var(--blackColor)
    span
      border-bottom: 2px var(--accentColor) solid
