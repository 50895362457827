.TableFilters-PopUp
  width: 700px
  .PopUp-Content
    padding-bottom: 0
.TableFilters
  width: 100%
  display: flex
  background-color: var(--backgroundColor)
  padding: 16px
  border-radius: 8px
  .Content-Header
    padding: 0
    color: var(--descriptionDark)

.Filters-Content
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
  grid-template-rows: min-content
  .SelectOption-Tag
    background-color: var(--descriptionLight)
  .Field_with_value
    .Field-Bar
      &:before,
      &:after
        width: 50%
        background: var(--accentColor)

  .Buttons-Container
    margin-top: 8px
    display: flex
    .Button
      &:first-child
        margin-right: 16px
  .Content-Spoiler
    display: grid
    grid-template-columns: 1fr
    grid-gap: 16px
  .Content-Header
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 8px
    border-bottom: 1px var(--descriptionLight) solid
    cursor: pointer
    outline: none
    appearance: none
    h4
      height: 20px
      display: flex
      align-items: center
      margin-top: 0
    svg
      margin-left: 12px
      height: 20px
      margin-top: -2px
      color: var(--descriptionDark)
      transition: all 0.2s ease
  .Spoiler_status_expanded
    .Content-Header
      border-bottom: 0px
      h4
        color: var(--blackColor)
      svg
        transform: rotate(-90deg)
    .Content-Body
      display: grid

  .Content-Body
    display: none
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 16px
    .Field
      input, label
        background: var(--backgroundColor)

  .SceneContent-Header, .Fieldset
    grid-column: 1/-1
  .Fieldset
    .Button
      display: none
    .Fields
      display: grid
      grid-template-columns: repeat(2, minmax(0, 1fr))
      grid-gap: 16px
