.EPM-InitMessage
  width: 100%
  height: calc(100% - 40px)
  display: flex
  justify-content: center
  align-items: center
  color: var(--labelColor)
.EPM-LoaderSkeletonGrid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 16px
  &:not(:last-child)
    margin-bottom: 32px
.EPM-LoaderSkeletonField
  height: 24px
.EPM-LoaderSkeletonTitle
  margin-bottom: 16px

.EstateParamsManager
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr))
  grid-gap: 16px
  .SceneContent-Header
    height: 32px
    background-color: var(--backgroundColor)
    padding: 0px 16px
    grid-column: 1/-1
    border-radius: 8px
