.SalesPlans
  .SceneContent-TableContainer
    overflow-x: auto
.SalesPlans-YearTitle
  display: flex
  align-items: center
  justify-content: center
  height: 24px
  width: 100%

.SalesPlans-AccentCell
  background-color: var(--backgroundColor)
  font-weight: 600
  color: var(--defaultText) !important

.SalesPlans-TotalPlanCell
  padding: 0 !important
  line-height: normal !important
  .SalesPlans-AccentCell
    padding: 4px 8px
    height: 100%
    width: 100%

.SalesPlans-SquadTitle
  display: flex
  align-items: center
  .Marker
    margin-right: 8px
  span
    color: var(--accentColor)
    text-decoration: underline
    &:hover
      text-decoration: none

.SalesPlan-Input
  width: 100%
  border: 0px
  border-bottom: 1px solid var(--inputBorder)
  &:focus
    border-bottom: 1px solid var(--accentColor)

.SalesPlan-InputAlt
  font-weight: 500
  color: var(--labelColor)
  white-space: nowrap
  margin-top: 4px

.SalesPlans-TotalCounts
  font-weight: 700

.SalesPlans-SectionTitle
  display: flex
  align-items: center
  .DecorateLink
    cursor: pointer
    text-decoration: underline
  .Icon
    font-size: 14px !important
    margin: 0px 8px
    color: var(--sideLinks)
