.SubscriptionEvent_status_theme_active
  color: #029E1E

.SubscriptionEvent_status_theme_unactive
  color: #F34375

.SubscriptionEvent-ResubscribeButton
  margin-top: 12px
  color: #fff
  text-decoration: underline
  &:hover
    text-decoration: none

.SubscriptionEvent
  .SpinnerContainer
    text-align: center
