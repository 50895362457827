.Site-Footer
  padding: 64px 0px
.Site-Footer-Container
  display: grid
  grid-template-columns: repeat(4, minmax(min-content, 1fr))
  grid-gap: 56px
.Site-Footer-Block
  .Footer-Block-Arrow
    display: none
    transition: all 0.3s ease
  .Site-TitleText
    margin-bottom: 16px
  .Footer-Block-Links
    display: grid
    grid-template-columns: 1fr
    grid-gap: 8px
  .Footer-Link
    &:hover
      color: var(--accentColor)
  .Contact-Data
    font-size: 16px
    font-weight: 700

.Site-Footer-LegacyBlock
  margin-top: 24px
  padding-top: 24px
  border-top: 1px var(--descriptionLight) solid
  display: flex
  justify-content: space-between
  align-items: center
  a
    color: var(--descriptionDark)
    &:hover
      color: var(--accentColor)
  .LegacyBlock-Links
    display: grid
    grid-template-columns: repeat(3, max-content)
    align-items: center
    grid-gap: 24px
  .LegacyBlock-Social
    display: grid
    grid-template-columns: repeat(4, max-content)
    align-items: center
    grid-gap: 24px
    color: var(--descriptionDark)
    a
      display: flex
      align-items: center
      justify-content: center
    svg
      font-size: 18px

@media screen and ( max-width: 1200px )
  .Site-Footer-LegacyBlock
    flex-direction: column
    align-items: center
    justify-content: center
    .LegacyBlock-Social
      margin-top: 24px

@media screen and ( max-width: 1024px )
  .Site-Footer-Container
    grid-template-columns: 1fr
    grid-gap: 16px
    .Site-Footer-Block
      width: 100%
      .Site-TitleText
        display: flex
        align-items: center
        justify-content: space-between
        border-bottom: 1px var(--descriptionLight) solid
        padding-bottom: 16px
      .Footer-Block-Arrow
        display: block
        font-size: 18px
        color: var(--descriptionDark)
      .Footer-Block-Links
        display: none
        padding-bottom: 16px
        border-bottom: 1px var(--descriptionLight) solid

    .Footer-ContactsBlock
      display: grid
      grid-template-columns: max-content 1fr
      align-items: center
      grid-gap: 24px
      .Site-TitleText
        margin-bottom: 0
      .Site-TitleText, .Footer-Block-Links
        border: 0
        padding-bottom: 0
      .Footer-Block-Links
        display: grid
        grid-template-columns: repeat(3, minmax(min-content, max-content))
        grid-gap: 24px
        justify-self: end
        align-items: center
  .Site-Footer-LegacyBlock
    .LegacyBlock-Links
      display: flex
      flex-wrap: wrap
      grid-gap: 16px
  .Site-Footer-Block_theme_active
    .Footer-Block-Arrow
      transform: rotate(180deg)
    .Footer-Block-Links
      display: grid !important

@media screen and ( max-width: 768px )
  .Site-Footer-Container
    .Footer-ContactsBlock
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      .Footer-Block-Links
        display: grid
        grid-template-columns: 1fr
        grid-gap: 8px
        text-align: center
  .Site-Footer-LegacyBlock
    .LegacyBlock-Links
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
    .LegacyBlock-Social
      grid-template-columns: repeat(3, max-content)
      p
        text-align: center
        grid-column: 1/-1
