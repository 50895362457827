.TableTagCloud
  grid-column: 1/-1
  display: flex
  flex-wrap: wrap
  .TagCloud-TagComponent
    font-size: 10px
    border-radius: 8px
    padding: 0px 6px
    min-height: 16px
    margin-bottom: 4px
