.History-DateLine
  width: 100%
  height: 16px
  display: flex
  justify-content: center
  position: relative
  align-items: center
  text-align: center
  margin-bottom: 32px
  &:not(:first-child)
    margin-top: 32px
  &:after
    content: ''
    width: 100%
    height: 1px
    position: absolute
    background-color: var(--inputBorder)
  .Date
    display: flex
    height: 16px
    background-color: #fff
    box-shadow: var(--whiteBoxShadow)
    position: relative
    z-index: 3
    padding: 0px 8px
    justify-content: center
    align-items: center
    border-radius: 8px
    font-size: 9px
    font-weight: 500
    color: var(--descriptionDark)
    text-transform: uppercase

.History-EventContainer
  display: flex
  margin-top: 24px
  .UserAvatar, .NoUserAvatar
    width: 24px
    height: 24px
    margin-right: 12px
    flex-shrink: 0
  .UserCredentials
    margin-right: 16px
    width: 120px
    line-height: 16px
    font-weight: 500
    flex-shrink: 0
    .UserName
      font-size: 12px
    .EventDate
      margin-top: 4px
      font-size: 11px
      color: var(--descriptionDark)

  .EventContent
    padding: 8px
    margin-top: -8px
    font-size: 12px
    width: 100%
    height: 100%
    display: flex

    .EventIcon
      width: 24px
      height: 24px
      border-radius: 100%
      margin-right: 8px
      display: flex
      justify-content: center
      align-items: center
      flex-shrink: 0
      box-shadow: var(--whiteBoxShadow)
      img
        width: 12px
    .EventIcon_theme_basic
      background-color: var(--descriptionLight)
      color: #fff
    .EventIcon_theme_outbound_call, .EventIcon_theme_inbound_call, .EventIcon_theme_task_status_done
      background-color: #BEF0C7
      color: var(--accentColor)
    // .EventIcon_theme_inbound_call
        // background: var(--accentGradient)
        // color: #fff
        // box-shadow: var(--accentBoxShadow)
    .EventIcon_theme_missed_call, .EventIcon_theme_task_status_pending
      background: var(--notyColor)
      color: #fff
      box-shadow: var(--whiteBoxShadow)
    .EventIcon_theme_sale
      // background-color: #BEF0C7
      color: var(--accentColor)
      border: 1px var(--accentColor) solid

    .Container
      width: 100%
      .Content
        font-size: 12px
      .Bolder
        text-decoration: underline
        font-weight: 600
      .Buttons
        margin-top: 8px
        display: flex
        font-size: 11px
        button
          border: 0
          padding: 0
          margin-right: 12px
          &:last-child
            margin-right: 0
        .Accent
          color: var(--accentColor)
          font-weight: 600
          text-decoration: underline
        .Default
          color: var(--descriptionDark)
    .Link
      text-decoration: underline
      color: var(--accentColor)

    .Task-Card
      margin-top: 8px
      padding: 0
      box-shadow: none
      margin-bottom: 0px
      .UserAvatar
        margin-right: 0
    .TaskResults
      .TaskConstructor-InputField
        background-color: #F2F4F6
        font-size: 12px
        padding: 8px
        border-radius: 8px
        height: 100%

    .Task-ResultBlock
      border-top: 1px var(--inputBorder) solid
      margin-top: 8px
      padding-top: 8px
      .Task-ResultTag
        padding: 2px 4px
        border-radius: 4px
        background-color: #BEF0C7
        color: var(--accentColor)
        margin-bottom: 4px
        display: inline-block
      .Task-ResultText
        margin: 0

    .AudioPlayer, .PendingAudioFile
      margin-top: 12px

  .EventContent_theme_white
    background-color: #fff
    border-top-right-radius: 8px
    border-bottom-right-radius: 8px
    border-bottom-left-radius: 8px
  .EventContent_theme_no_margin
    padding: 0

  .EventOneLine
    display: block
    .HighLighted
      text-decoration: underline

.History-EventContainer_fixed_margin
  margin-top: 16px
  .EventDate
    margin-top: 0

.History-Content
  .SmartInput
    margin-top: 32px

.CallComment
  width: 100%
  padding: 4px 8px
  background: var(--backgroundColor)
  margin-top: 4px
  border-radius: 4px
  margin-bottom: 0
  span
    color: var(--descriptionDark)

.PendingAudioFile
  .Button_theme_transparent-hug
    margin-left: 4px
    font-size: 10px
    text-decoration: underline

.RenderCall-AudioToTextButton
  background-color: #2680EB !important
  .Button-IconContainer
    margin-right: 0
