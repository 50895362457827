.WidgetTile
  width: 100%
  height: 100%
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  border-radius: 8px
  padding: 16px
  display: flex
  flex-direction: column
.WidgetTile-Title
  font-weight: 700
  font-size: 14px
  line-height: 18px
  text-transform: uppercase
  margin-bottom: 16px
.WidgetTile-CounterPeriod
  padding: 4px 8px
  font-size: 12px
  line-height: 12px
  background-color: var(--borderColor)
  border-radius: 4px
  color: var(--sideLinks)
.WidgetTile-Content
  display: grid
  grid-gap: 16px
  flex: 1
  height: 100%
  margin-bottom: 16px
.WidgetTile-Content_theme_loading
  width: 100%
  height: calc(100% - 32px)
  display: flex
  align-items: center
  .ModuleSpinner-Container
    margin-top: -16px
