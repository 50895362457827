.Site-ForgotPassword-DropTile
  width: 100%
  display: grid
  grid-template-columns: 48px 1fr 18px
  grid-gap: 16px
  padding: 24px
  border: 2px var(--descriptionLight) solid
  border-radius: 16px
  cursor: pointer
  .DropTile-Icon
    width: 48px
    height: 48px
    border-radius: 100%
    background-color: var(--backgroundColor)
    display: flex
    justify-content: center
    align-items: center
    font-size: 18px
    color: var(--descriptionDark)
  .DropTile-ConfirmIcon
    width: 18px
    height: 18px
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size: 10px
    background-color: var(--accentColor)
    color: #fff
    opacity: 0
  .Data-Title
    margin-bottom: 8px
.Site-ForgotPassword-DropTile_theme_active
  border: 2px var(--accentColor) solid
  .DropTile-Icon
    color: var(--accentColor)
  .DropTile-ConfirmIcon
    opacity: 1
