.Dialer-ContactBlock
  font-size: 11px
  p
    margin: 0


  .ContactBlock-Title
    color: #A7ACB0
  .ContactBlock-ContactName
    color: #fff
