.Layout
  width: 100vw
  display: flex
  height: 100%
.Layout-Content
  width: calc(100% - 64px)
  position: relative
  padding: 0px 16px
  margin-left: 64px
  .Submenu
    top: 64px
    position: fixed
    width: calc(100vw - 64px)
    padding: 0px 16px
    left: 64px
    z-index: 3
.Layout-Content_theme_callbar_offset
  margin-bottom: 64px
