.Dialer-Container
  width: calc(100% - 64px) !important
  left: 0 !important
  margin: 0 !important
  bottom: 0 !important
  padding: 0 !important
  height: 64px !important
  margin-left: 64px !important
  transform: none !important

.Dialer-Bar
  padding: 0 !important
  margin: 0px 16px !important
  box-shadow: var(--whiteObject)
  background: none !important
  border-top-left-radius: 16px !important
  border-top-right-radius: 16px !important
  overflow: visible !important

.CallDirectionIcon
  width: 18px
  height: 18px
