.ParamPopUp-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px

.ParamPopUp-Container
  .Fieldset
    display: grid
    grid-template-columns: 1fr 12px
    align-items: center
    grid-gap: 16px
