.NewTaskConstructor
  .Checkbox
    margin-top: 12px
    margin-bottom: 0
.Tasks-InlineSelectors
  width: 100%
  margin-top: 12px
  display: flex
  flex-wrap: wrap
  border-bottom: 1px var(--inputBorder) solid
  margin-bottom: 8px
  .SelectableLabel
    width: auto
    max-width: 100%
    display: flex
    margin-bottom: 8px
    flex-shrink: 0
    .NoStyles
      background: none
      color: #000
    span
      padding: 2px 4px
      background-color: #F2F4F6
      color: var(--blackColor)
      font-weight: 400
      margin-right: 4px
      border-radius: 4px
  .SelectableLabel-PopUpContainer
    .TaskDatePicker
      top: 32px
      left: -4px
      transform: translateX(calc(-50% - 80px))
    .DropdownBox-Container
      top: 24px

.TaskConstructor-InputField
  margin-top: 4px
  width: 100%
  border: 0px
  padding: 0px
  min-height: 36px
  max-height: 100px
  margin-bottom: 4px
  background-color: #fff
  &::placeholder
    color: var(--descriptionDark)
    font-weight: 500
