.ScrollBar-Holder
  width: 100% !important

.ScrollBar-TrackY
  width: 8px !important
  right: -4px !important
  background: none !important

.ScrollBar-ThumbY
  width: 8px !important
  background: var(--pushedButton) !important
