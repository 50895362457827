.Site-SignPage
  width: 100vw
  min-height: 100vh
  background: linear-gradient(.25turn, #fff 0% 50%, var(--accentDark) 50% 100%)
  padding-bottom: 80px

.Site-SignNav
  width: 100%
  height: 64px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0px 64px
  position: relative
  z-index: 3

.Site-SignPage-Controlls
  font-size: 16px
  font-weight: 700
  grid-template-columns: repeat(3, max-content) !important

.Site-SignPage-Illustration
  width: 50%
  height: 100%
  position: absolute
  right: 0
  bottom: 0
  background-position: top center
  background-repeat: no-repeat

.Site-SignNav_theme_sign
  .Site-SignPage-Controlls
    color: #fff
    .Button
      svg
        color: var(--acidColor)
    .LangPicker
      color: #fff

@media screen and ( max-width: 1200px)
  .Site-SignNav
    padding: 0px 20px
  .Site-SignPage-Illustration
    background-position: top center

@media screen and ( max-width: 1024px )
  .Site-SignNav_theme_sign
    .Site-SignPage-Controlls
      color: var(--blackColor)
      .Button
        svg
          color: var(--descriptionDark)
      .LangPicker
        color: var(--blackColor)
  .Site-SignNav
    background-color: #fff
  .Site-SignPage
    background: var(--accentDark)
    overflow-y: auto
  .Site-SignPage-Controlls
    font-size: 16px
    font-weight: 700
    color: var(--blackColor)
    grid-template-columns: repeat(3, max-content) !important
    .Button_color_accent
      background-color: var(--accentColor)
    .Button
      svg
        color: #fff
    .LangPicker
      color: var(--blackColor)
      svg
        color: var(--descriptionDark)
  .Site-SignPage-Illustration
    width: 100%
    height: 100vh
    background-size: cover
    z-index: 1

  .Site-SignPage-Content
    width: 100%
    display: flex
    justify-content: center
    position: relative
    z-index: 2
    padding-bottom: 80px
    .AuthForm
      background-color: #fff
      margin-left: 0
      padding: 48px
      border-radius: 16px
      .AuthForm-Title, .AuthForm-Description
        text-align: center

@media screen and ( max-width: 767px )
  .Site-SignPage-Controlls
    grid-template-columns: max-content !important
    p, .Button
      display: none
    .LangPicker
      display: inline-flex !important
  .AuthForm-MobileSignStep
    text-align: center
    display: block !important
    a
      color: var(--accentColor)

@media screen and ( max-width: 575px )
  .Site-SignPage
    min-height: auto
    padding-bottom: 0
  .Site-SignPage-Content
    padding: 0
    .AuthForm
      border-radius: 0
      margin: 0
      width: 100%
