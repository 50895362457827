.InBaseCounter
  width: 152px
.InBaseCounter-Container
  width: 120px
  height: 120px
  position: relative
  a
    transform: scale(0) !important
.InBaseCounter-Ring
  transform: rotate(-90deg)
.InBaseCounter-Title
  display: flex
  width: calc(100% - 16px)
  height: calc(100% - 16px)
  justify-content: center
  align-items: center
  position: absolute
  top: 8px
  left: 8px
  flex-direction: column
  font-size: 32px
  font-weight: 700
  line-height: 32px
  border-radius: 100%
  span
    font-size: 12px
    color: var(--labelColor)
