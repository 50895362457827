.Site-AccountTile
  width: 100%
  padding: 24px
  background-color: #fff
  border-radius: 16px
  display: grid
  grid-template-columns: 56px 1fr
  grid-gap: 16px

  .AccountTile-Avatar
    width: 56px
    height: 56px
    background-color: var(--backgroundColor)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100%
    .Avatar-Icon
      font-size: 24px
      color: var(--descriptionDark)
  .AccountTile-Data
    display: grid
    grid-template-columns: 1fr
    grid-gap: 16px
    .Data-Header, .Data-Details
      display: flex
      justify-content: space-between
      .AccountDetails
        margin-right: 16px
      .Buttons
        flex-shrink: 0

    .Data-Details
      align-items: center
      .Button
        font-size: 14px
    .Details-Counters
      display: flex

  .AccountTile-Button
    width: 24px
    height: 24px
    border-radius: 8px
    border: 2px var(--descriptionDark) solid
    box-sizing: content-box
    .Button-IconContainer
      margin-right: 0
      font-size: 14px
      color: var(--descriptionDark)
  .AccuntTile-Counter
    display: grid
    grid-template-columns: 1fr
    grid-gap: 8px
    .Data
      font-size: 18px
      font-weight: 600
      color: var(--descriptionDark)
    &:not(:last-child)
      padding-right: 16px
      border-right: 1px var(--inputBorder) solid
      margin-right: 16px
    .Accent
      color: var(--accentColor)
    .Noty
      color: var(--notyColor)
