.InfoCard
  width: 100%
  padding: 8px 16px
  border-radius: 8px
  border: 1px var(--descriptionLight) solid
  cursor: pointer
  &:hover
    background-color: var(--backgroundColor)

.InfoCard_theme_active
  background-color: var(--backgroundColor)

.InfoCard-Header
  display: flex
  justify-content: space-between
  align-items: center
  .DropdownBox-Container
    right: 0
    left: auto
.InfoCard-Title
  font-size: 14px
  color: var(--blackColor)
  font-weight: 700
  margin-bottom: 8px

.InfoCard-Employees, .InfoCard-Occupation
  font-size: 11px
  width: 100%
  font-weight: 700
  color: var(--descriptionDark)
  text-transform: uppercase

.InfoCard-Header-ButtonsContainer
  margin-left: 8px
  display: flex

.InfoCard-Header-ButtonContainer
  width: 24px
  height: 24px
  margin-right: 8px
  &:last-child
    margin-right: 0
    button
      font-size: 12px

.InfoCard-OccupationDescription
  font-size: 14px
  font-weight: 400
  color: var(--blackColor)
  // margin-top: -8px
.InfoCard-Skeleton
  cursor: default
  &:hover
    background: none !important
