.RadioGroup
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: start
  .Radio
    position: relative
    align-items: flex-start
    &:not(:last-child)
      margin-bottom: 8px
      &::after
        content: ''
        display: block
        width: 6px
        height: 100%
        position: absolute
        border-left: 1px var(--inputBorder) solid
        border-right: 1px var(--inputBorder) solid
        top: 14px
        left: 3px
        // transform: translateX(-50%)
        // background-color: #ffffff
        z-index: 1
    &:last-child
      margin-bottom: 0
    .Radio-Tumbler
      margin-top: 4px
      margin-right: 8px
  .Radio-Skeleton
    &::after
      display: none !important
