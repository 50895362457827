.Site-DropdownLink
  padding: 16px
  display: grid
  grid-template-columns: 24px 1fr
  grid-gap: 12px
  height: 100%
  &:hover
    background-color: var(--backgroundColor)
.Site-DropdownLink-Icon
  width: 24px
  height: 24px
  font-size: 18px
  display: flex
  align-items: center
  justify-content: center
  color: var(--accentColor)
.Site-DropdownLink-Container
  width: 100%
  display: grid
  grid-gap: 12px
  grid-template-columns: 1fr
  grid-template-rows: min-content
  .Link-Title
    font-size: 16px
    font-weight: 700
    color: var(--blackColor)
  .Link-Description
    font-size: 14px
    line-height: 24px
    color: var(--descriptionDark)
    font-weight: 400
