.Button
  display: inline-flex
  height: 36px
  font-size: 13px
  line-height: 12px
  font-weight: 500
  padding: 0px 16px
  text-align: center
  border-radius: 12px
  justify-content: center
  align-items: center
  cursor: pointer
  appearance: none !important
  outline: none !important
  color: #1D1F29
  border: 0px
  transition: all 0.3s ease
  transition-property: background, color, border

.Button_theme_circle
  padding: 0
  width: 24px
  height: 24px
  font-size: 18px
  border-radius: 100%
  text-align: center
  display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  border: 0px !important
  .Button-IconContainer
    font-size: 11px
    margin-right: 0 !important

.Button_theme_transparent, .Button_theme_bounded
  background: none !important

.Button_theme_transparent
  border: 0 !important
.Button_theme_transparent-hug
  background: none !important
  height: auto
  padding: 0

.Button_fill_white
  background: #ffffff
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07)
  &:hover
    background: var(--descriptionLight)
    color: #fff
  &:active
    color: #fff !important
    background: var(--blackColor)

.Button_fill_black
  background: var(--blackColor)
  color: #fff !important
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07)
  &:hover
    background: var(--blackLightColor)
    color: #fff
  &:active
    color: #fff !important
    background: var(--blackColor)

.Button_fill_accent
  background: var(--accentColor)
  color: #fff !important
  &:hover
    background: var(--accentDark)
    color: #fff
  &:active
    color: #fff
    background: var(--blackColor)
.Button_fill_accentLight
  background: var(--accentLightColor)
  color: #fff !important
  &:hover
    background: var(--accentColor)
    color: #fff
  &:active
    color: #fff
    background: var(--blackColor)

.Button_fill_noty
  background: var(--notyColor)
  color: #fff !important
  &:hover
    color: #fff
    background: #FF7199
  &:active
    color: #fff
    background-color: var(--blackColor)

.Button_color_accent
  color: var(--accentColor)
.Button_color_white
  color: #fff
.Button_color_noty
  color: var(--notyColor)
.Button_color_black
  color: var(--blackColor)
.Button_color_gray
  color: var(--descriptionDark)

.Button_border_accent
  border: 1px var(--accentColor) solid
.Button_border_noty
  border: 1px var(--notyColor) solid
.Button_border_gray
  border: 1px var(--inputBorder) solid
  &:hover
    border: 1px var(--descriptionDark) solid
  &:active
    border: 1px var(--blackColor) solid

.Button_color_white
  color: var(--accentColor)
  &:hover
    box-shadow: none
    color: #fff
    background: var(--accentDarken)
  &:active
    box-shadow: none
    color: #fff
    background-color: var(--blackColor)

.Button-IconContainer
  width: 16px
  height: 16px
  display: flex
  justify-content: center
  align-items: center
  font-size: 12px

.Button_icon_position_left
  .Button-IconContainer
    order: 0
    margin-right: 8px
  .Button-Content
    order: 1

.Button_size_28
  height: 28px
  border-radius: 8px
  padding: 0px 12px

.Button_size_32
  height: 32px
  border-radius: 8px
  padding: 0px 12px

.Button_size_48
  height: 48px
  border-radius: 16px
  padding: 0px 16px
  font-size: 16px
  font-weight: 600
  .Button-IconContainer
    font-size: 16px
