.SideNav
  width: var(--sideNavWidth)
  height: 100%
  background-color: #fff
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.07)
  flex-shrink: 0
  padding-bottom: 76px
  z-index: 5
  position: fixed
  top: 0
  left: 0
  display: grid
  grid-column: 1fr
  grid-template-rows: 64px 1fr
  grid-gap: 16px

  nav
    overflow-y: auto
    height: 100%
    -ms-overflow-style: none
  nav::-webkit-scrollbar
    display: none

  .Logo
    width: 64px
    height: 64px
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 16px

    span
      display: block
      width: 46px
      height: 46px
      border-radius: 100%
      background-color: #B4B4B4
