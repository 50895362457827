.NextTimePicker
  min-width: 130px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  border-radius: 8px
  .NextTimePicker-Header
    margin-top: 8px
    height: 24px
    font-size: 14px
    font-weight: 700
    display: flex
    align-items: center
    margin-bottom: 8px
    padding: 0px 8px
  .NextTimePicker-Element
    position: relative
    list-style: none
    padding: 4px 8px
    font-size: 13px
    cursor: pointer
    color: var(--blackColor)
    text-align: left
    &:hover
      color: #fff
      background: var(--accentColor)
      box-shadow: var(--whiteBoxShadow)
    &:after
      content: ''
      position: absolute
      width: calc(100% - 16px)
      background-color: var(--borderColor)
      height: 1px
      bottom: -1px
      left: 8px
    &:last-child
      &:after
        display: none
  .NextTimePicker-Container
    height: calc(100% - 40px)
    overflow-y: scroll
