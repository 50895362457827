.CallInfoPopUp
  position: absolute
  width: 260px
  bottom: 64px
  background-color: #39393D
  left: 0
  border-top-left-radius: 16px
  border-top-right-radius: 16px
  font-size: 12px
  font-weight: 500
  padding: 16px
  padding-top: 0
  padding-right: 12px
  p
    margin-bottom: 4px
  .ScrollBar-Holder
    margin-top: 4px
    width: 100% !important
  .ScrollbarsCustom-Content
    display: block !important
  .ScrollbarsCustom-Thumb
    background: #4E4F53 !important
  .Header-Title
    color: #fff
  .Header-ButtonContainer
    width: 16px
    height: 16px
    padding: 0px
  .IconicButton_theme_basic
    background-color: #4E4F53
    color: #A7ACB0
    font-size: 8px


  .StepsProgressBar
    margin-top: 8px

.CallInfoPopUp-WhiteContent
  color: #fff

.CallInfoPopUp-Item
  padding: 8px
  padding-bottom: 16px
  border-bottom: 1px #4E4F53 solid
  color: #A7ACB0 !important
  &:hover
    background-color: #4E4F53
