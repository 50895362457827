.ExtensionTile
  width: 100%
  border-radius: 8px
  background-color: #fff
  display: grid
  grid-gap: 16px
  grid-template-columns: 1fr
  cursor: pointer
  &:hover
    box-shadow: var(--whiteBoxShadow)
.ExtensionTile-Image
  width: 100%
  height: 160px
  object-fit: cover
  object-position: center center
  border-radius: 8px
  border: 1px var(--borderColor) solid
.ExtensionTile-Info
  padding: 0px 16px 16px 16px
  .Title
    font-size: 14px
    font-weight: 600
  .CategoryName
    color: var(--descriptionDark)
  .Scores-Container
    display: grid
    grid-template-columns: 1fr max-content
    grid-gap: 8px
    align-items: center
    margin-top: 12px
  .Scores
    width: 100%
    display: grid
    grid-gap: 16px
    grid-template-columns: repeat(3, minmax(0, max-content))
    .Icon
      color: var(--descriptionDark)
    .SetupButton_theme_active
      .Icon
        color: var(--accentColor)
.InstalledAppMarker
  font-size: 12px
  font-weight: 400
  color: var(--accentColor)
  .Icon
    margin-right: 4px
