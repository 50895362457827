.FileManager-FileItem
  display: flex
  padding: 8px 0px
  &:not(:last-child)
    border-bottom: 1px var(--inputBorder) solid
  .FileItem-Icon
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    background-color: var(--pushedButton)
    color: #ffffff
    font-size: 11px
    border-radius: 100%
    flex-shrink: 0
  .FileItem-Info
    width: 100%
    margin-left: 12px
  .FileItem-InfoHeader
    display: flex
    justify-content: space-between
    margin-bottom: 4px
  .FileDate
    font-weight: 600
  .FileStatus
    font-weight: 600
  .FileItem-Description
    display: flex

  .FileItem-Text
    width: 100%
    span
      display: grid
      grid-template-columns: 1fr
      grid-gap: 8px
  .FileItem-Options
