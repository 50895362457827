.Task-Card
  width: 100%
  padding: 8px
  border-radius: 8px
  background-color: #fff
  box-shadow: var(--whiteBoxShadow)
  cursor: pointer
  &:not(:last-child)
    margin-bottom: 8px

  .Card-Header
    display: flex
    margin-bottom: 8px

    .Header-Icon
      width: 12px
      height: 4px
      color: var(--descriptionLight)
      display: flex
      align-items: flex-start

  .Card-Body
    font-size: 12px
    line-height: 18px
    display: flex
    justify-content: space-between
    .Card-Loader
      width: 16px
      height: 16px
      img
        width: 12px
        height: 12px

  .Card-Markers
    display: flex
    margin-top: 8px
    flex-wrap: wrap

    .Markers-Container
      width: auto
      margin-right: 8px
      display: flex
      align-items: center
      margin-bottom: 8px

      .Marker
        display: flex
        height: 16px
        border-radius: 4px
        align-items: center
        &:not(:last-child)
          margin-right: 8px

        .Marker-Icon
          width: 16px
          height: 16px
          display: flex
          align-items: center
        .Marker-Text
          font-size: 10px

      .CheckList, .Attachment, .Description
        color: #BFC0CC

  .Card-Avatars
    width: auto
    flex: auto
    display: flex
    justify-content: flex-end
    .UserAvatar
      margin-left: 4px

    .AllMembersTag
      padding: 2px 4px
      background-color: var(--borderColor)
      font-size: 10px
      color: var(--descriptionLight)
      border-radius: 4px
      display: flex
      align-items: center

.Task-Card_theme_crossout
  text-decoration: line-through !important
  text-decoration-color: var(--blackColor) !important
