.Dialer-LeadsBlock
  padding: 0
  height: 100%
  color: #A7ACB0
  p
    margin: 0

.LeadsBlock
  width: 100%
  height: 64px
  padding: 12px 32px
  display: flex
  align-items: center
