.NoInfoRow
  width: 100%
  text-align: center
  color: var(--descriptionDark)
  td
    padding: 8px 0px
    width: 100%
.SceneContent-TableLoader
  width: 100%
  height: 100%
  position: absolute
  background-color: rgba(255,255,255, 0.4)
  top: 0
  left: 0
  .ModuleSpinner-Container
    display: flex
    justify-content: center
    align-items: center
    height: 100%
