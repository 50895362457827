.PlanGauge
  width: 100%
  height: 108px
  overflow: hidden
  position: relative
.PlanGauge-ValueBox
  width: 100%
  height: 100%
  position: absolute
  left: 0
  bottom: 12px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-end
  font-size: 18px
  font-weight: 700
  line-height: 18px
  .ValueBox-Total
    font-size: 14px
    color: var(--labelColor)
    margin-top: 8px
.PlanGauge-Description
  display: flex
  font-size: 13px
  font-weight: 600
  color: var(--labelColor)
  margin-top: 8px
  justify-content: space-between
  .Description-Value
    font-size: 17px
    font-weight: 800
    color: var(--defaultText)
