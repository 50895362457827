.Submenu
  width: 100%
  display: flex
  list-style: none
  height: 40px
  background-color: var(--backgroundColor)
  position: relative
  align-items: flex-start
  &::after
    content: ''
    display: block
    position: absolute
    bottom: 0px
    width: calc(100% - 32px)
    height: 1px
    background-color: var(--descriptionLight)
    z-index: 2
.Submenu-Item-Container
  display: flex
  align-items: center
  height: 22px
.Submenu-Item
  font-size: 13px
  font-weight: 600
  color: var(--descriptionDark)
  height: 100%
  margin-right: 24px
  cursor: pointer
  flex-shrink: 0
  &:last-child
    margin-right: 0
  a
    text-decoration: none
    color: var(--descriptionDark)
.Submenu-Item-Link
  position: relative
  &:hover
    color: var(--blackColor)
    &:after
      content: ''
      display: block
      position: absolute
      bottom: -18px
      width: 100%
      height: 3px
      background: var(--accentColor)
      box-shadow: var(--whiteBoxShadow)
      z-index: 3
.Submenu-Item-Counter
  min-width: 14px
  height: 14px
  border-radius: 4px
  display: flex
  justify-content: center
  align-items: center
  border: 1px #DBDBDB solid
  margin-right: 8px
  font-size: 10px
  font-weight: 400
.Submenu-Item-Icon
  width: 14px
  height: 14px
  border-radius: 100%
  display: flex
  align-items: center
  justify-content: center
  font-size: 11px
  margin-left: 4px
  text-align: center
  flex-shrink: 0
  &:hover
    background-color: var(--descriptionLight)
    color: #fff

.Submenu-Item-Link_active
  color: var(--blackColor) !important
  &:after
    content: ''
    display: block
    position: absolute
    bottom: -18px
    width: 100%
    height: 3px
    background: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    z-index: 3
.Submenu-Item_theme_disabled
  cursor: text
  &:hover
    color: var(--descriptionDark)
    background: none !important
    &::after
      display: none
