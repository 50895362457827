.Site-ProfileNav
  width: 316px
  padding: 24px
  background-color: #fff
  border-radius: 16px
  display: grid
  grid-template-columns: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  grid-gap: 4px
  align-self: self-start

.Site-ProfileNavLink
  padding: 8px 16px
  display: flex
  align-items: center
  cursor: pointer
  border-radius: 8px
  color: var(--blackColor)
  &:hover
    background-color: var(--accentColor)
    color: #fff
    svg
      color: #fff
  svg
    font-size: 18px
    margin-right: 12px
    color: var(--descriptionDark)

.Site-ProfileNavLink_status_active
  background-color: var(--accentColor)
  color: #fff
  svg
    color: #fff !important
