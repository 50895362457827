.TableContact
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-gap: 8px
  .TableContact-Data
    display: flex
    .TableContact-Icon
      width: 16px
      height: 16px
      display: flex
      justify-content: center
      align-items: center
      margin-right: 8px
      margin-top: 2px
      color: var(--descriptionDark)
      flex-shrink: 0
