.Site-ConversionBlock
  padding: 80px 0px
  background-color: var(--accentDark)
  background-position: top left, bottom right
  background-repeat: no-repeat, no-repeat
  .Site-SectionTitle, .Site-SectionSubTitle
    color: #fff
  .ConversionBlock-Container
    display: flex
    justify-content: center
  .ConversionBlock-Form
    margin-top: 32px
    width: 700px
    display: grid
    grid-template-columns: 1fr max-content
    grid-gap: 16px
    align-items: center
    .Field
      margin-top: 0
      border: 0 !important
    label
      display: none
  .Site-PolicyAgreement
    grid-column: 1/-1
    color: #fff
    a
      color: #fff
      text-decoration: underline !important

@media screen and ( max-width:  768px)
  .Site-ConversionBlock
    .ConversionBlock-Form
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      max-width: 500px
      .Field
        width: 100%
        max-width: 500px
    .Site-PolicyText
      text-align: center
