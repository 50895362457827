.EstateImage
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center center
  border-radius: 4px
.EstateImage-Thumbnail
  display: flex
  justify-content: center
  align-items: center
  background-color: #EDEFF1
  font-size: 24px
  color: #fff
  border-radius: 4px
