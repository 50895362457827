.MapMarker
  width: 32px
  height: 32px
  background-color: #27C043
  color: #fff
  border-radius: 32px
  border-bottom-left-radius: 0px
  border: 2px #fff solid
  display: flex
  justify-content: center
  align-items: center
  font-size: 12px
  box-shadow: var(--whiteBoxShadow)
  position: relative
  &::after
    content: ''
    width: 4px
    height: 4px
    position: absolute
    bottom: -6px
    left: -6px
    background-color: #27C043
    border-radius: 100%
    box-shadow: var(--whiteBoxShadow)
