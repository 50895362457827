.SmartButton-Container
  width: 64px
  height: 64px
  position: fixed
  bottom: 0
  left: 0
  background-color: #fff
  box-shadow: 0px -4px 32px rgba(191, 192, 204, 0.25)
  display: flex
  align-items: center
  justify-content: center

.Dialer-Caller
  width: 36px
  height: 36px
  border-radius: 100%
  background: var(--blackColor)
  box-shadow: var(--whiteObject)
  color: #A7ACB0
  border: 0
  display: flex
  align-items: center
  justify-content: center
  .RingsContainer
    margin-top: 2px
    width: 15px
    height: 15px
    display: grid
    grid-gap: 3px
    grid-template-columns: repeat(2, 5px)
    span
      display: block
      width: 5px
      height: 5px
      border: 1px #A7ACB0 solid
      border-radius: 100%

.DialerRing-ActiveCall
  border: 1px #029E1E solid !important
  background-color: #029E1E
