.DialerButton
  width: 32px
  height: 32px
  font-size: 13px
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  border: 0
  outline: none
  appearance: none
  cursor: pointer
  background: #4E4F53
  color: #A7ACB0
  &:hover
    color: #39393D
    background: #D0D5DA

.DialerButton-Text
  margin-left: 6px

.DialerButton_theme_cancel
  background: var(--notyGradient)
  color: #fff
  box-shadow: var(--notyBoxShadow)
  &:hover
    background: var(--notyGradient) !important
    color: #fff !important
    box-shadow: none

.DialerButton_theme_stroked
  background: none
  font-size: 16px
  &:hover
    background: #A7ACB0
    color: #39393D

.DialerButton_theme_call
  background: linear-gradient(134.49deg, #008D19 14.64%, #00C023 85.36%) !important
  box-shadow: 0px 4px 8px rgba(0, 192, 35, 0.25)
  color: #fff !important
  &:hover
    box-shadow: none

.DialerButton_theme_textContent
  width: auto !important
  border-radius: 16px
  padding: 0px 8px
  svg
    width: 12px !important
    text-align: center
    margin-top: -2px
  &:hover
    background: #4E4F53
    color: #A7ACB0

.DialerButton_theme_defaultActive
    color: #39393D
    background: #D0D5DA
    span
      background-color: #39393D !important
    &:hover
      color: #39393D
      background: #D0D5DA
