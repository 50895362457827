.SceneContent-Header
  width: 100%
  height: 40px
  display: grid
  grid-template-columns: max-content 1fr max-content
  align-items: center
  grid-gap: 8px
  // display: flex
  // align-items: center
  // justify-content: space-between

.Header-Title
  color: var(--defaultText)
  font-size: 14px
  font-weight: 700

.Header-ButtonsContainer
  display: flex
  justify-content: flex-end

.Header-ButtonContainer
  width: 20px
  height: 20px
  &:not(:last-child)
    margin-right: 8px
  button
    font-size: 11px
