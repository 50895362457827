.AudioPlayer-TrackBar
  width: 100%
  background: var(--inputBorder)
  height: 1px
  position: relative

  .TrackBar-Pin
    width: 9px
    height: 9px
    border-radius: 100%
    border: 2px var(--accentColor) solid
    position: absolute
    top: -4px
    background-color: var(--borderColor)
    cursor: pointer

  .TrackBar-ProgressBar
    position: absolute
    top: 0
    left: 0
    width: 0px
    height: 1px
    background-color: var(--accentColor)
