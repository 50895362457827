.Site-Faq
  padding: 80px 0px
  background-color: var(--backgroundColor)
  background-position: top right
  background-repeat: no-repeat

.Site-Question
  width: 100%
  padding: 16px 24px
  background-color: #fff
  border-radius: 16px
  cursor: pointer
  .Question-Header
    display: flex
    align-items: center
    justify-content: space-between
  .Question-Arrow
    width: 24px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    color: var(--descriptionDark)
    font-size: 24px
    margin-left: 24px
    transition: all 0.1s ease
  .Question-Body
    margin-top: 16px
    display: none
.Site-Question_status_active
  .Question-Body
    display: block
  .Question-Arrow
    transform: rotate(-180deg)

.Site-Faq-Container
  margin-top: 64px
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px
.Site-Faq-Buttons
  width: 100%
  display: flex
  justify-content: center
