.Module-Container
  margin-top: 120px
  display: flex
  flex-direction: column
  height: calc(100vh - 120px)
.Module-SubmitFormButtons
  background: #fff
  height: 64px
  width: calc(100% + 32px)
  margin-left: -16px
  padding: 0px 16px
  border-top: 0
  box-shadow: var(--whiteBoxShadow)
  display: flex
  align-items: center
  margin-top: 0 !important
  flex-shrink: 0
.Manager-Container
  margin-top: 104px
  height: calc(100vh - 104px)
