.TaskResults-PopUp
  .Checkbox
    margin-bottom: 0px !important
    margin-top: 0px !important

.TaskResults-Container
  display: grid
  grid-template-columns: 1fr
  grid-gap: 16px
  .Field-Checkbox
    margin-bottom: 0

.TaskResults-SimpleInputBlock
  margin-top: 12px
  display: flex
  .SimpleInput
    width: 100%
    margin-right: 8px
    background-color: #F2F4F6
    height: 28px
    font-size: 12px
    padding: 2px 8px
    border: 0
    border-radius: 8px

  .SimpleInput-SubmitButton
    font-size: 12px
    text-transform: none
    height: 28px
    background: var(--accentColor)
    color: #fff
    min-width: 85px
    padding: 0px 12px
    font-weight: 400
    display: flex
    justify-content: center
    align-items: center
    line-height: normal
    border-radius: 8px

.TaskResultsBlock
  margin-top: 0 !important
  margin-bottom: 16px
  p
    padding-left: 28px
