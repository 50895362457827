.OfficeInfoBlock
  width: 100%
  padding: 8px
  background-color: var(--backgroundColor)
  border-radius: 8px
  display: flex
.OfficeInfoBlock-Icon
  width: 24px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  background-color: #fff
  border-radius: 100%
  flex-shrink: 0
  color: var(--labelColor)
  margin-right: 12px
  box-shadow: var(--whiteBoxShadow)
.OfficeInfoBlock-Title
  font-weight: 700
  margin-bottom: 4px
  display: flex
  align-items: center
  justify-content: space-between
  .Icon
    margin-left: 8px
    font-size: 11px
    color: var(--labelColor)
    cursor: pointer
    &:hover
      color: var(--defaultText)
.OfficeInfoBlock-Content
.OfficeInfoBlock-Content-InfoLine
  display: flex
  .InfoLine-Title
    font-weight: 700
    color: var(--sideLinks)
    margin-right: 4px
  .InfoLine-Content
