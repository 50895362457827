.FunnelBlock-NewDeals
  background-color: var(--backgroundColor)
  padding-right: 16px
  flex-shrink: 0
  .DealsCounter
    font-size: 32px
    font-weight: 700
.NewDeals-Lost
  font-size: 14px
  width: 100%
  height: 32px
  display: flex
  justify-content: center
  align-items: center
  font-weight: 600
