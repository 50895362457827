.Task-Column
  width: 100%
  flex-shrink: 0
  height: 100%

.Task-Column_theme_add
  background-color: #E2E8ED
  padding: 0px 16px
  height: 32px
  display: flex
  align-items: center
  text-transform: uppercase
  font-size: 12px
  color: #A7ACB0
  font-weight: 500
  box-shadow: none

.Column-Title
  display: flex
  margin-bottom: 16px
.Title-Marker
  width: 8px
  height: 8px
  flex-shrink: 0
  border-radius: 100%
  margin-right: 8px
  margin-top: 8px
.Column-TitleInfo
.Column-TasksCounter
  color: var(--descriptionDark)
  span
    font-weight: 600
.Title-Text
  font-size: 14px
  font-weight: 700
  width: 100%
.Title-Button
  width: 24px
  height: 24px
  flex-shrink: 0

.Column-Body
  .AddTask
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    border: 1px var(--descriptionLight) dashed
    border-radius: 8px
    height: 32px
    font-size: 11px
    font-weight: 700
    color: var(--descriptionDark)
    text-transform: uppercase
    background: none
    padding: 0
    outline: none
    appearance: none
    margin-bottom: 8px
  .AddTaskFieldBlock
    display: flex
    flex-direction: column
    margin-bottom: 8px

  .AddTaskField
    width: 100%
    border-radius: 8px
    border: 0px
    background-color: #fff
    box-shadow: var(--whiteBoxShadow)
    padding: 8px
    resize: none
    min-height: 42px

    &::placeholder
      color: var(--descriptionLight)

.TaskButtons-Container
  margin: 8px 0px
  display: flex
  align-items: center
  .Save
    height: 24px
    background: var(--accentColor)
    box-shadow: var(--whiteBoxShadow)
    border: 0px
    border-radius: 8px
    color: #fff
    margin-right: 12px
    display: flex
    align-items: center
    justify-content: center
    min-width: 80px
    width: 80px
    font-size: 12px
    text-transform: none
    img
      width: 28px
      height: 28px

  .Cancel
    width: 20px
    height: 20px
