.FunnelBlock
  display: grid
  grid-column: 1fr
  grid-auto-rows: 160px 32px
  grid-gap: 16px
  text-align: center
  .Label
    font-size: 12px
    font-weight: 700
    color: var(--descriptionDark)

.FunnelBlock-Label
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  height: 35px
  p
    font-size: 14px
    font-weight: 700
    margin-bottom: 8px
  .FunnelBlock-Label-Border
    width: 100%
    height: 4px
    border-top-left-radius: 4px
    border-top-right-radius: 4px

.FunnelBlock-BlockContent
  min-height: 143px
  display: flex
  justify-content: center
  align-items: center
.FunnelBlock-SubBlockContent
  width: 100%
  height: 32px
  display: flex
  justify-content: center
  align-items: center

.FunnelBlock-Steps
  display: flex
  width: 100%
  .StepBlock,.LooseBlock
    &:not(:last-child)
      margin-right: 16px
