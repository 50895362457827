.EstateModule-Container

.EstateManager
  .LeftSide, .RightSide
    display: grid
    grid-template-columns: 1fr
    grid-gap: 16px
    padding: 16px
    overflow-y: auto
    &::after
      content: ""
      display: block
      height: 1px
      width: 100%
  .RightSide
    grid-template-rows: 184px
    grid-auto-rows: minmax(1fr, max-content)
    padding-left: 8px
  .LeftSide
    height: 100%
    grid-auto-rows: minmax(min-content, max-content)
    padding-right: 8px
  .EstateManager-TopBlock
    display: grid
    grid-template-columns: 152px 1fr
    grid-gap: 16px
.EstateManager-Form
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 16px
  .SceneContent-Header, .Fieldset, .FullWidth
    grid-column: 1/-1
  .Fieldset
    display: grid
    grid-template-columns: 1fr 16px
    grid-gap: 16px
    .Fields
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 16px
.TagCloud-Root
  grid-column: 1/-1
  margin-top: -16px
