.ContactPopUpManager
  display: grid
  grid-template-columns: repeat(2,1fr)
  grid-gap: 16px
  .SceneContent-Header, .Fieldset, .PopupSpinner-Container, .ContactCard
    grid-column: 1/-1
  .PopupSpinner-Container
    margin: 8px 0px
  .Fieldset
    display: grid
    grid-template-columns: 1fr 16px
    grid-gap: 16px
    align-items: center
    .Fields
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 16px
  .ContactsOptions
    grid-column: 1/-1
    position: relative
    margin: 0
    padding: 0
    .DropdownBox
      box-shadow: none
      padding: 0
      .Field
        margin: 0 !important
        margin-bottom: 12px !important
        position: sticky
        top: 0
        left: 0
        background-color: #fff
        z-index: 2
