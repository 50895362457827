.Site-Nav
  width: 100%
  height: 64px
  background-color: #fff
  padding: 0px 64px
  display: grid
  grid-template-columns: repeat(2, max-content) 1fr
  align-items: center
  position: sticky
  top: 0
  left: 0
  z-index: 3
  transition: all 0.4s ease
  .Logo
    flex-shrink: 0
    margin-right: 40px
.Site-Nav_theme_transparent
  background: none
  .Site-NavLink, .Site-Nav-Controlls, .LangPicker, .SignInLink
    color: #fff
  .Site-DropdownMenu-Container
    background-color: var(--blackColor)
  .Site-DropdownLink-Container
    .Link-Title
      color: #fff
  .Site-DropdownLink
    &:hover
      background-color: #33353E
  .Site-Nav-Menu
    background-color: var(--blackColor)
.Site-Nav-Menu
  display: grid
  grid-template-columns: repeat(5, max-content)
  align-items: center

.Site-NavLink
  font-size: 16px
  color: var(--blackColor)
  font-weight: 700
  cursor: pointer
  height: 64px
  display: flex
  align-items: center
  padding: 0px 20px
  transition: all 0.3s ease
  &:hover
    color: var(--accentColor)
  svg
    transition: all 0.3s ease

.Site-NavLink-Arrow
  font-size: 14px
  color: var(--descriptionDark)
  margin-left: 8px
.Site-Nav-Controlls
  display: grid
  grid-template-columns: repeat(4, max-content)
  grid-gap: 40px
  align-items: center
  justify-self: end
  .Site-NavLink
    padding: 0

@media screen and ( max-width:  1200px)
  .Site-Nav
    padding: 0px 20px
    .Logo
      margin-right: 0
  .Site-Hamburger-Container
    margin-right: 24px
    flex-shrink: 0
  .Site-Nav-Menu
    display: none
    position: absolute
    top: 64px
    left: 0px
    grid-template-columns: 1fr
    width: 100%
    background-color: #fff
    padding: 0px 20px
    overflow-y: auto
    max-height: calc(100vh - 64px)
    height: auto
    grid-template-rows: repeat(4, minmax(0, max-content))
    .Site-NavLink
      width: 100%
      justify-content: space-between
      border-bottom: 1px var(--descriptionLight) solid
      font-size: 18px
      padding: 0px

      &:last-child
        border-bottom: 0
    .Site-NavLink_theme_active
      color: var(--accentColor)
      svg
        transform: rotate(180deg)
    .Site-NavLink_theme_dropdown
      svg
        font-size: 24px
    .Site-DropdownMenu-Container
      padding: 24px 0px
      &:not(:last-child)
        border-bottom: 1px var(--descriptionLight) solid

@media screen and ( max-width:  767px)
  .Site-Nav-Controlls
    grid-template-columns: max-content
    .Site-NavLink, .TryFreeButton, .LangPicker
      display: none
  .Site-Nav-Menu
    .Site-DropdownMenu-Container
      grid-template-columns: repeat(2, minmax(0, 1fr))
@media screen and ( max-width:  575px)
  .Site-Nav-Menu
    .Site-DropdownMenu-Container
      grid-template-columns: 1fr
