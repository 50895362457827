.Benefits
  margin-top: -160px
  padding-top: 80px
  background-repeat: no-repeat
  background-position: top 80px right 0px

.Benefits-Container
  display: grid
  grid-template-columns: repeat(3, minmax(0, 1fr))
  grid-gap: 32px
.Benefit
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
  background-color: #fff
  padding: 24px
  border-radius: 16px
  display: grid
  grid-template-columns: 64px minmax(0, 1fr)
  grid-gap: 24px
  grid-template-rows: min-content
  .Benefit-Icon
  .Benefit-Title
  .Benefit-Description
    grid-column: 1/-1

@media screen and ( max-width: 1200px)
  .Benefit
    grid-template-columns: 48px minmax(0, 1fr)
    .Benefit-Icon
      width: 48px
      height: 48px

@media screen and ( max-width: 1024px)
  .Benefits-Container
    grid-template-columns: repeat(2, minmax(0, 1fr))
  .Benefit
    align-items: center

@media screen and ( max-width: 768px)
  .Benefits-Container
    grid-template-columns: 1fr
