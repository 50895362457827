.TechPage
  width: 100%
  background-color: var(--backgroundColor)
  .Site-SignNav
    position: sticky
    top: 0
    left: 0
    background-color: #fff
.TechPage-Body
  padding: 64px 0px
