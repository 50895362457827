.CallEvent
  padding: 16px
  background-color: #39393D
  border-radius: 16px
  width: 100%
  color: #A7ACB0
  display: flex
  align-items: flex-start
  font-weight: 600
  font-size: 11px
  p
    margin: 0
  img
    margin-top: 4px
    flex-shrink: 0
    margin-right: 12px

.CallEvent-Content
  width: 100%

.CallEvent-Error
  display: block
  background-color: #F3AAAD
  color: #BF2121
  padding: 8px
  margin-bottom: 16px
  border-radius: 12px
  font-size: 10px
  .ErrorTitle
    margin-bottom: 6px
    text-transform: uppercase

.CallEvent-CloseButton
  width: 16px
  height: 16px
  line-height: 16px
  text-align: center
  background-color: #4E4F53
  color: #A7ACB0
  font-size: 8px
  flex-shrink: 0

.CallEvent-Header
  width: 100%
  display: flex
  margin-bottom: 8px

.CallEvent-Type
  display: flex
.CallEvent-Title
  width: 100%
  margin-right: 12px !important
  span
    text-decoration: underline

.CallEvent-Contact
  margin-bottom: 8px
  .Contact-Name
    color: #fff

.CallEvent-Buttons
  display: flex
  justify-content: space-between
  .DialerButton
    width: 28px
    height: 28px
    font-size: 11px
.CallEvent-ActionButtons
  display: flex
  &:not(:last-child)
    .DialerButton
      margin-right: 12px
