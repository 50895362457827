.GeneralConversion
  display: flex
  .ConversionColumn
    display: flex
    flex-direction: column
    .ConversionColumn-Title
      font-size: 12px
      font-weight: 600
      color: var(--labelColor)
    .ConversionColumn-Value
      font-size: 18px
      font-weight: 700
      color: var(--accentColor)
    &:not(:last-child)
      margin-right: 12px
      padding-right: 12px
      border-right: 1px var(--borderColor) solid
.ConversionColumn_theme_negativeCounter
  .ConversionColumn-Value
    color: var(--notyColor) !important
