.CallTracker
  min-width: 266px
  height: 40px
  background-color: #4E4F53
  border-radius: 32px
  display: flex
  align-items: center
  justify-content: space-between
  flex-shrink: 0
  font-size: 12px
  p
    margin: 0
  .DialerButton
    margin-right: 2px

.CallTracker-Settings
  display: flex
  align-items: center

.CallTracker-PhoneNumber
  font-size: 12px
  color: #fff
  margin: 0px 16px !important


.CallTracker-Recorder
  margin-right: 12px
  color: #A7ACB0

.CallTracker-Recorder_theme_active
  color: var(--notyColor)
  animation: pulse 1.5s infinite

@keyframes pulse
  0%
    color: #A7ACB0
  50%
    color: var(--notyColor)
  100%
    color: #A7ACB0

.CallTracker-CallTimer
  margin-right: 16px !important
  font-size: 12px
  color: #A7ACB0

.CallTracker-CallTimer_theme_active
  color: #fff
