.Site-CreateAccount-Form
  max-width: 650px
  width: 100%
  padding: 24px 32px
  background-color: #fff
  border-radius: 16px
  margin-left: 50%
  transform: translateX(-50%)
  margin-top: 80px
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: min-content
  grid-gap: 24px

.AccountIdentifier-Field
  position: relative

.AccountIdentifier-Error
  width: auto
  padding: 2px 8px
  font-size: 12px
  background-color: var(--failColor)
  color: var(--notyColor)
  margin-top: 8px
  border-radius: 8px
.AccountIdentifier-HelperIcon
  position: absolute
  top: 4px
  right: 0
  width: 18px
  height: 18px
  background-color: var(--blackColor)
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  font-size: 10px
  cursor: pointer
