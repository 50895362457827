.PhotoTile
  width: 100%
  height: 100%
  position: relative
  cursor: pointer
  overflow: hidden
  img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center center
    transition: transform .25s ease
  &:hover
    img
      transform: scale(1.1)

  .PhotoTile-Options
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#EDEFF1,0.7)
    display: none
    justify-content: center
    align-items: center
    border-radius: 4px
  .PhotoTile-OptionsContainer
    display: grid
    grid-template-columns: repeat(2, 48px)
    grid-gap: 12px

  .Option
    width: 48px
    height: 48px
    display: flex
    justify-content: center
    align-items: center
    background-color: #000
    cursor: pointer
    color: #fff
    font-size: 16px
    &:hover
      .Avatar
        color: #E9B500
      .Layout
        color: #008DBA
      .Edit
        color: var(--accentColor)
      .Delete
        color: var(--notyColor)
    .Avatar_theme_active
      color: #E9B500
    .Layout_theme_active
      color: #008DBA
.PhotoTile-Skeleton
  width: 100%
  height: 100%

.PhotoTile-Markers
  width: 24px
  height: 100%
  position: absolute
  top: 0
  left: 0
  display: grid
  grid-template-columns: 1fr
  grid-auto-rows: minmax(min-content, max-content)
  z-index: 3
  .Avatar
    background-color: #E9B500

.PhotoTile-Marker
  color: #fff
  width: 24px
  height: 24px
  display: flex
  justify-content: center
  align-items: center

.EditButton
  position: absolute
  background-color: rgba(0,0,0, 0.4)
  color: #ffffff
  right: 0
  top: 0
  transition: all 0.5s ease
  cursor: pointer
  z-index: 3
  &:hover
    background-color: #000
.ZoomButton
  position: absolute
  background: #000
  color: #ffffff
  right: 0
  bottom: 0
