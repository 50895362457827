.Header
  width: calc(100vw - 64px)
  height: 64px
  display: flex
  align-items: center
  justify-content: space-between
  background-color: var(--backgroundColor)
  position: fixed
  top: 0px
  left: 64px
  padding: 0px 16px
  z-index: 4

.Header-ChildGroup
  display: flex
  align-items: center
.Hamburger
  width: 18px
  height: 20px
  background-color: #B4B4B4
  margin-right: 24px
