.Dialer-NumberPad-Container
  position: relative
.Dialer-NumberPad
  padding: 16px
  background-color: #39393D
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  bottom: 48px
  right: 0px
  border-top-left-radius: 16px
  border-top-right-radius: 16px
  .Field
    font-weight: 400
    margin: 0px 32px
    input, label
      background: none
    input
      color: #fff

.NumberPad-Close
  width: 100%
  display: flex
  justify-content: flex-end
  .Dialer-CloseButton
    width: 16px
    height: 16px
    font-size: 8px
    background-color: #4E4F53
    color: #A7ACB0

.NumberPads-PadButtons
  margin-top: 24px
  display: grid
  grid-template-columns: repeat(3, max-content)
  grid-column-gap: 34px
  grid-row-gap: 12px
  align-content: center
  grid-template-areas: "a a a" "a a a" "a a a" "a a a" "b b b"

  .DialerButton_theme_call
    grid-area: b
    justify-self: center

.DialerButton_theme_number_pad
  .RingsContainer
    margin-top: 1px
    width: 16px
    height: 16px
    display: grid
    grid-gap: 2px
    grid-template-columns: repeat(3, 4px)
    margin-left: 1px
    span
      display: block
      width: 3px
      height: 3px
      background-color: #A7ACB0
      border-radius: 100%
  &:hover
    span
      background-color: #39393D
